import React, { createElement, useState } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { ClientPaymentPlan } from 'shared/enum/client/ClientPaymentPlan'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'

import { useProfileContext } from 'hooks/ProfileContext'

import { BasicInfo } from './components/BasicInfo'
import { Confirm } from './components/Confirm'
import { PhotographerOrientations } from './components/PhotographerOrientations'
import { Responsible } from './components/Responsible'
import { Scheduling } from './components/Scheduling'
import { Service } from './components/Service'

import classes from './SessionAdd.module.scss'

const SessionRoute: React.FC<any> = ({ component, exact, path, state }) => {
  const render = (props: RouteComponentProps) => createElement(component, { ...props, state })
  return <Route exact={exact} path={path} render={render} />
}

export const SessionAdd: React.FC<RouteComponentProps> = ({ match }) => {
  const [profile] = useProfileContext()
  const state = useState<Model.Session[]>([{} as Model.Session])
  if (profile?.client.payment_plan !== ClientPaymentPlan.POSTPAID) {
    if (Number(profile?.client.credit?.balance || 0) <= 0) {
      return <Redirect to="/payment" />
    }
  }
  return (
    <Container>
      <Card>
        <div className={classes.wrapper}>
          <Switch>
            <SessionRoute path={`${match.path}`} exact component={Service} state={state} />
            <SessionRoute path={`${match.path}/info`} component={BasicInfo} state={state} />
            <SessionRoute path={`${match.path}/responsible`} component={Responsible} state={state} />
            <SessionRoute path={`${match.path}/confirm`} component={Confirm} state={state} />
            <SessionRoute path={`${match.path}/scheduling`} component={Scheduling} state={state} />
            <SessionRoute path={`${match.path}/photographer`} component={PhotographerOrientations} state={state} />
          </Switch>
        </div>
      </Card>
    </Container>
  )
}
