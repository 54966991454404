import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { Label } from 'components/Label'
import { useFilterContext } from '../../hooks/filters'

import classes from './Filter.module.scss'

const animatedComponents = makeAnimated()

const sessionStates = new Map([
  [1, { className: 'naoagendada', label: 'Não agendada' }],
  [2, { className: 'agendada', label: 'Agendada' }],
  [3, { className: 'cancelado', label: 'Cancelado' }],
  [4, { className: 'entregapendente', label: 'Entrega Pendente' }],
  [5, { className: 'entregue', label: 'Entregue' }],
  [6, { className: 'noshow', label: 'NoShow Cliente' }],
  [7, { className: 'noshow', label: 'NoShow Fotógrafo' }],
  [8, { className: 'emrevisao', label: 'Em Revisão' }]
])

export default () => {
  const [, filter] = useFilterContext()

  const options = Array.from(sessionStates).map((state: any) => {
    const [id, { label }] = state
    return {
      value: id,
      label: label
    }
  })

  const onChange = (selected: any) => filter('status', selected?.map((select: any) => select.value) ?? null)

  return (
    <Label className={classes.input}>
      <p>Status</p>
      <Select
        placeholder={'Selecione um status'}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'black'
          }
        })}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        options={options}
      />
    </Label>
  )
}
