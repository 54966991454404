import React, { Fragment, useMemo } from 'react'

import { ProfileContextProvider, useProfileContext } from './hooks/ProfileContext'
import { AppContextProvider } from './hooks/AppContext'

import { getProfile } from './services/profile'
import api from './services/api'

import { Routes } from './Routes'

import classes from './App.module.scss'

const Load: React.FC = props => {
  const [, setProfile] = useProfileContext()

  const urlParams = new URLSearchParams(window.location.search)
  const magicToken = urlParams.get('magictoken')

  useMemo(async () => {
    if (magicToken) {
      await api('/api/auth/magic/?token=' + magicToken)

      getProfile().then(setProfile)
    }
  }, [magicToken, setProfile])

  return <Fragment {...props} />
}

export const App: React.FC = () => (
  <div className={classes.app}>
    <AppContextProvider>
      <ProfileContextProvider>
        <Load>
          <Routes />
        </Load>
      </ProfileContextProvider>
    </AppContextProvider>
  </div>
)
