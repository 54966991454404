export function parseStrings(...str: any[]): string {
  return str.reduce((prev, curr) => {
    if (curr === undefined) {
      return prev
    }

    if (prev === '') return curr

    return prev + ', ' + curr
  }, '')
}
