import React, { useState } from 'react'
import { isPast, isValid, parseISO, subHours } from 'date-fns'

import { SessionStatus } from 'shared/enum/session/SessionStatus'
import { FormCheck } from 'shared/form/FormCheck'
import { Modal } from 'shared/modal'
import { Button } from 'shared/ui/button/Button'
import { toFormData } from 'shared/util/form-data'

import classes from './CancelSessionModal.module.scss'

interface CancelSessionModalProps {
  admin?: boolean
  close: () => void
  onChange: () => void
  session: Resource.SessionResource
}

export const CancelSessionModal: React.FC<CancelSessionModalProps> = ({ admin, close, onChange, session }) => {
  const scheduledFor = session.scheduled_for && parseISO(session.scheduled_for)
  const isFeeApplicable = isValid(scheduledFor) && isPast(subHours(scheduledFor as Date, 24))
  const [hasFee, setHasFee] = useState(isFeeApplicable)
  const confirm = () => {
    const body = toFormData({
      has_fee: hasFee,
      status: SessionStatus.CANCELED
    })
    fetch(`/api/sessions/${session.id}`, { body, method: 'POST' }).then(onChange).then(close)
  }

  return (
    <Modal size="sm">
      <div className={classes.title}>
        <h3>Cancelar ensaio</h3>
      </div>
      <div className={classes.divider} />
      <div className={classes.description}>
        <h4>Confirma o cancelamento do ensaio ID {session.id}?</h4>
        {isFeeApplicable && (
          <p>Cancelamentos realizados no período das 24h anteriores ao agendamento da sessão possuem taxa de 30%.</p>
        )}
        {admin && (
          <FormCheck onChange={setHasFee} checked={hasFee}>
            Efetuar cobrança da taxa de 30%.
          </FormCheck>
        )}
      </div>
      <div className={classes.divider} />
      <div className={classes.buttons}>
        <Button outline onClick={close}>
          Cancelar
        </Button>
        <Button onClick={confirm}>Confirmar</Button>
      </div>
    </Modal>
  )
}
