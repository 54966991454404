import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'
import { Divider } from 'shared/ui/divider/Divider'
import { Icon } from 'shared/ui/icon'
import { classNames } from 'shared/util/class-names'
import { toFormData } from 'shared/util/form-data'
import { castNumber } from 'shared/util/number'

import { useProfileContext } from 'hooks/ProfileContext'

import { CreditCardPayment, CreditCardPaymentData } from './components/CreditCardPayment'
import { PaymentService } from './components/PaymentService'

import classes from './Payment.module.scss'
import { useModal } from 'shared/modal'
import { ErrorModal } from 'shared/ui/payment/ErrorModal'

// TODO:
// Flag na lista de cidades para marcar a cidade como disponível no self service?

// TODO:
const cities = ['São Paulo', 'Porto Alegre', 'Rio de Janeiro']
const commercialContactMessage = encodeURIComponent('Olá! Gostaria de realizar uma cotação para ')
const commercialContactUrl = `https://api.whatsapp.com/send?phone=5511937309960&text=${commercialContactMessage}`

export const Payment: React.FC<RouteComponentProps> = ({ history }) => {
  const [, setProfile] = useProfileContext()
  const [paymentData, setPaymentData] = useState<CreditCardPaymentData>()
  const [services, setServices] = useState<Model.Service[]>([])
  const [selectedService, setSelectedService] = useState<Model.Service>()
  const [selectedRule, setSelectedRule] = useState<any>()
  const [selectedPlace, setSelectedPlace] = useState<string>()
  const [submitting, setSubmitting] = useState(false)
  const [method] = useState('2') // Cartão de crédito

  useEffect(() => {
    console.log('rule: ', selectedRule)
  }, [selectedRule])

  const paymentErrorModal = useModal(ErrorModal)

  useEffect(() => {
    fetch('/api/services')
      .then(response => response.json())
      .then(({ data }) => {
        setServices(data)
        setSelectedService(data?.[0])
        setSelectedRule(data?.[0]?.rules?.[0])
      })
  }, [])

  const handlePaymentData = useCallback(setPaymentData, [])
  const handleService = (service: Model.Service) => {
    setSelectedService(service)
    setSelectedRule(service.rules?.[0])
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setSubmitting(true)
    const body = toFormData({
      PaymentMethod: method,
      Products: [
        {
          Code: '1',
          Description: selectedService?.name,
          Quantity: 1,
          UnitPrice: selectedRule?.final_price
        }
      ],
      ...paymentData
    })
    fetch(`/api/transactions`, { body, method: 'POST' })
      .then(response => response.json())
      .then(({ error, error_message, data: { message, status } }) => {
        if (error) {
          paymentErrorModal.open({ message: error_message || message })
        } else {
          if (status === 3) {
            // TODO: remover daqui
            fetch(`/api/auth/user`)
              .then(response => response.json())
              .then(({ data }) => setProfile(data))
              .then(() => history.push(`/sessions/add`))
          } else if (status === 8) {
            paymentErrorModal.open({ message: 'Transação recusada!' })
          } else {
            paymentErrorModal.open({ title: 'Aguarde um momento,', message: `Transação com status: ${status}` })
          }
        }
      })
      .finally(() => setSubmitting(false))
  }
  return (
    <Container className={classes.container}>
      <h1 className={classes.title}>Pagamento</h1>
      <div className={classes.request}>
        <Card className={classes.card} rounded="2x" variant="light-grey">
          <h1>O que você precisa?</h1>
          <h3>Selecione o serviço</h3>
          <div className={classes.services}>
            {services.map(service => (
              <PaymentService key={service.id} current={selectedService} service={service} onClick={handleService} />
            ))}
          </div>
          <h3>Tamanho do espaço</h3>
          <div className={classes.buttons}>
            {selectedService?.rules?.map((rule, i) => {
              const active = selectedRule?.id === rule?.id
              const handleRule = () => setSelectedRule(selectedService?.rules?.[i])
              return (
                <Button key={i} active={active} onClick={handleRule} outline variant="info">
                  {rule.name}
                </Button>
              )
            })}
            <Button className={classes.more} href={commercialContactUrl} variant="info">
              Precisa de mais espaço?
            </Button>
          </div>
          <h3>Local</h3>
          <div className={classes.buttons}>
            {cities.map(city => {
              const active = selectedPlace === city
              const handleCity = () => setSelectedPlace(city)
              return (
                <Button key={city} active={active} onClick={handleCity} outline variant="info">
                  {city}
                </Button>
              )
            })}
            <Button className={classes.more} href={commercialContactUrl} variant="info">
              Outra cidade?
            </Button>
          </div>
        </Card>
        <div>
          <Card className={classes.order} rounded="2x" variant="black">
            <h2>Seu pedido {selectedRule && <span>{'R$ ' + castNumber(selectedRule?.final_price)}</span>}</h2>
            <Divider />
            <h3>{selectedRule?.discount && <span>{selectedRule?.discount.title}</span>}</h3>
            {selectedRule && (
              <div className={classes.line}>
                <span>{selectedRule?.rule?.main?.title}</span>
                <span>R$ {castNumber(selectedRule?.rule?.main?.value)}</span>
              </div>
            )}
            {selectedRule?.rule?.items?.map((item: any, index: number) => (
              <div className={classes.line} key={index}>
                <span>{item?.title}</span>
                {typeof item?.value === 'string' && <span>{item.value}</span>}
                {typeof item?.value === 'number' && <span>+ {castNumber(item.value)}</span>}
              </div>
            ))}
          </Card>
        </div>
        <form className={classNames(classes.payment, submitting && classes.submitting)} onSubmit={handleSubmit}>
          <Card rounded="2x" variant="light-grey">
            <h2>Informações de pagamento</h2>
            <CreditCardPayment onChange={handlePaymentData} />
          </Card>
          <div className={classes.submit}>
            <Button size="lg">EFETUAR PAGAMENTO →</Button>
            {submitting && (
              <div className={classes.loader}>
                <Icon.CircleNotch size="2x" spin />
              </div>
            )}
          </div>
        </form>
      </div>
    </Container>
  )
}
