import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import InputMask from 'react-input-mask'

import Steps from '../../../components/Steps'
import CallImage from '../../../components/Company/CallImage'
import Btn from '../../../components/Button'

import Img from '../../../assets/images/side-image.png'
import { updateClient } from '../../../services/client'

import { Wrapper } from '../../../components/Wrapper'
import { Main } from '../../../components/Main'
import { Before } from '../../../components/Before'
import { Content } from '../../../components/Content'
import { Call } from '../../../components/Call'
import { Form } from '../../../components/Form'
import { Label } from '../../../components/Label'
import { Input } from '../../../components/Input'
import { Text } from '../../../components/Text'
import { FormAddress } from 'shared/form/FormAddress'

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;

  .outline {
    margin-right: 30px;
  }

  @media only screen and (max-width: 960px) {
    justify-content: space-between;

    .outline {
      margin-right: 0;
    }
  }
`

const Company = () => {
  const [cnpj, setCnpj] = useState('')
  const [socialName, setSocialName] = useState('')
  const [address, setAddress] = useState<any>()
  const logo = useState<File | undefined>(undefined)

  const changeCnpj: (event: React.ChangeEvent<HTMLInputElement>) => void = e => setCnpj(e.target.value)
  const changeSocialName: (event: React.ChangeEvent<HTMLInputElement>) => void = e => setSocialName(e.target.value)
  const changeAddress = (result: google.maps.places.PlaceResult) => setAddress(result)

  const isCnpjValid = () => cnpj.length > 0 && cnpj.match('_') === null
  const isValid = () => cnpj.length > 0 && isCnpjValid() && socialName.length > 0 && address !== undefined

  const saveForm = (e: any) => {
    if (!isValid()) {
      e.preventDefault()
      return null
    }
    updateClient(
      {
        identification: cnpj,
        corporate_name: socialName,
        address
      },
      logo[0]
    )
  }

  return (
    <Wrapper>
      <Main>
        <Before>
          <img src={Img} alt="sofa" />
        </Before>
        <Content>
          <Steps className={'company'} />

          <Call>Empresa</Call>
          <Text>Precisamos completar suas informações e criar o seu login.</Text>

          <CallImage logo={logo} />

          <Form>
            <Label>
              CNPJ
              <InputMask mask="99.999.999/9999-99" onChange={changeCnpj}>
                <Input
                  type="text"
                  className={isCnpjValid() === true ? 'valid' : 'error'}
                  name="name"
                  placeholder="xx.xxx.xxx/xxxx-xx"
                />
              </InputMask>
            </Label>
            <Label>
              Razão Social
              <Input
                type="text"
                onChange={changeSocialName}
                className={socialName.length > 0 ? 'valid' : 'error'}
                name="name"
                placeholder="Nome inscrito no CNPJ"
              />
            </Label>
            <Label>
              Endereço
              <FormAddress onChange={changeAddress} />
            </Label>
          </Form>
          <Buttons>
            <Link to="/">
              <Btn className={'outline'}>Mais Tarde</Btn>
            </Link>

            <Link onClick={saveForm} to="/onboard/users">
              <Btn className={isValid() === true ? '' : 'invalid'}>Sim &#8594;</Btn>
            </Link>
          </Buttons>
        </Content>
      </Main>
    </Wrapper>
  )
}

export default Company
