import React, { useEffect, useState } from 'react'
import { useRef } from 'react'

import { FormGroup } from 'shared/form/FormGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormSelect } from 'shared/form/FormSelect'
import { FormText } from 'shared/form/FormText'
import { States } from 'shared/util/address/states'

import classes from './CreditCardPayment.module.scss'

interface CreditCardPaymentProps {
  onChange: (_: CreditCardPaymentData) => void
}

const creditCardPaymentData = {
  Address: {
    CityName: '',
    Complement: '',
    CountryName: 'Brasil',
    District: '',
    Number: '',
    StateInitials: '',
    Street: '',
    ZipCode: ''
  },
  Customer: {
    Email: '',
    Identity: '',
    Name: '',
    Phone: ''
  },
  PaymentObject: {
    CardNumber: '',
    ExpirationDate: '',
    Holder: '',
    SecurityCode: ''
  }
}

export type CreditCardPaymentData = typeof creditCardPaymentData

type KeysParam<T = CreditCardPaymentData> = [keyof T, string]

interface ViacepAddress {
  bairro?: string
  cep?: string
  complemento?: string
  ddd?: string
  gia?: string
  ibge?: string
  localidade?: string
  logradouro?: string
  siafi?: string
  uf?: string
}

export const CreditCardPayment: React.FC<CreditCardPaymentProps> = ({ onChange }) => {
  const [paymentData, setPaymentData] = useState(creditCardPaymentData)

  const numberInput = useRef<any>()

  const handleChange = (...key: KeysParam) => (value: string) => {
    if (key[0] === 'Address' && key[1] === 'ZipCode') {
      const postalCode = value.replace(/[^0-9]/, '')
      if (postalCode.length === 8) {
        fetch(`https://viacep.com.br/ws/${postalCode}/json/`)
          .then(response => response.json())
          .then((place: ViacepAddress) => {
            numberInput?.current?.focus()
            setPaymentData(paymentData => ({
              ...paymentData,
              Address: {
                CityName: place.localidade ?? '',
                Complement: place.complemento ?? '',
                CountryName: 'Brasil',
                District: place.bairro ?? '',
                Number: '',
                StateInitials: place.uf ?? '',
                Street: place.logradouro ?? '',
                ZipCode: value
              }
            }))
          })
          .catch(console.error)
      }
    }

    setPaymentData(paymentData => ({ ...paymentData, [key[0]]: { ...paymentData[key[0]], [key[1]]: value } }))
  }
  useEffect(() => onChange(paymentData), [onChange, paymentData])
  return (
    <div className={classes.form}>
      <h4 className={classes.section}>Dados do cartão</h4>
      <FormGroup className={classes.card} label="Número do cartão">
        <FormMasked onChange={handleChange('PaymentObject', 'CardNumber')} mask="credit-card" required />
      </FormGroup>
      <FormGroup className={classes.expiration} label="Validade">
        <FormMasked onChange={handleChange('PaymentObject', 'ExpirationDate')} mask="month-year" required />
      </FormGroup>
      <FormGroup className={classes.code} label="CVV">
        <FormMasked onChange={handleChange('PaymentObject', 'SecurityCode')} mask="cvv" required />
      </FormGroup>
      <FormGroup className={classes.holder} label="Nome do titular do cartão">
        <FormText onChange={handleChange('PaymentObject', 'Holder')} required />
      </FormGroup>
      <h4 className={classes.section}>Dados do titular do cartão</h4>
      <FormGroup className={classes.name} label="Nome">
        <FormText onChange={handleChange('Customer', 'Name')} required />
      </FormGroup>
      <FormGroup className={classes.identity} label="CPF/CNPJ">
        <FormMasked onChange={handleChange('Customer', 'Identity')} mask="cnpj-cpf" required />
      </FormGroup>
      <FormGroup className={classes.email} label="E-mail">
        <FormText onChange={handleChange('Customer', 'Email')} type="email" required />
      </FormGroup>
      <FormGroup className={classes.phone} label="Telefone">
        <FormMasked onChange={handleChange('Customer', 'Phone')} mask="phone" required />
      </FormGroup>
      <h4 className={classes.section}>Endereço de cobrança</h4>
      <FormGroup className={classes.postalCode} label="CEP">
        <FormMasked
          onChange={handleChange('Address', 'ZipCode')}
          value={paymentData?.Address?.ZipCode}
          mask="postal-code"
          required
        />
      </FormGroup>
      <FormGroup className={classes.city} label="Cidade">
        <FormText onChange={handleChange('Address', 'CityName')} value={paymentData?.Address?.CityName} required />
      </FormGroup>
      <FormGroup className={classes.neighborhood} label="Bairro">
        <FormText onChange={handleChange('Address', 'District')} value={paymentData?.Address?.District} required />
      </FormGroup>
      <FormGroup className={classes.state} label="UF">
        <FormSelect
          onChange={handleChange('Address', 'StateInitials')}
          value={paymentData?.Address?.StateInitials}
          required>
          <option value=""></option>
          {Object.entries(States).map(([uf]) => (
            <option key={uf} value={uf}>
              {uf}
            </option>
          ))}
        </FormSelect>
      </FormGroup>
      <FormGroup className={classes.street} label="Endereço">
        <FormText onChange={handleChange('Address', 'Street')} value={paymentData?.Address?.Street} required />
      </FormGroup>
      <FormGroup className={classes.number} label="Número">
        <FormText
          ref={numberInput}
          onChange={handleChange('Address', 'Number')}
          value={paymentData?.Address?.Number}
          required
        />
      </FormGroup>
      <FormGroup className={classes.complement} label="Complemento">
        <FormText onChange={handleChange('Address', 'Complement')} value={paymentData?.Address?.Complement} />
      </FormGroup>
    </div>
  )
}
