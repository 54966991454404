import React from 'react'

import Status from './Status'
import SessionDate from './SessionDate'
import SessionCreated from './SessionCreated'
import Search from './Search'
import { Button } from 'shared/ui/button/Button'
import { useMetaContext } from 'pages/sessions/hooks/meta'

import classes from './Filter.module.scss'
import { Services } from './Services'
import Address from './Address'

export const Filter = () => {
  const [meta] = useMetaContext()

  const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    meta('update')
  }

  return (
    <>
      <div className={classes.grid}>
        <Search />
        <Address />
        <Status />
        <Services />
        <SessionDate />
        <SessionCreated />
      </div>
      <div className={classes.row}>
        <Button type="button" onClick={onClick}>
          Filtrar
        </Button>
      </div>
    </>
  )
}
