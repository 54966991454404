import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from 'shared/ui/button/Button'
import { Container } from 'shared/ui/container/Container'
import { Divider } from 'shared/ui/divider/Divider'

import { useProfileContext } from 'hooks/ProfileContext'

import classes from './Navbar.module.scss'

export const Navbar: React.FC = () => {
  const [profile, setProfile] = useProfileContext()
  const history = useHistory()

  const logOut = () => {
    fetch('/api/auth/logout')
      .then(() => setProfile(null))
      .then(() => history.push('/'))
  }
  const credit = Number(profile?.client.credit?.balance || 0).toLocaleString('pt-BR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })
  return (
    <nav className={classes.navbar}>
      <Container className={classes.container}>
        <Button outline to="/">
          <img src="/img/birdie-logo.svg" alt="Logo Birdie" />
        </Button>
        {profile ? (
          <div className={classes.content}>
            <Divider vertical />
            <div className={classes.links}>
              <Button to="/sessions">Ensaios</Button>
              <Button to="/sessions/add">Criar ensaio</Button>
              <Button to="/profile">Configurações</Button>
            </div>
            <span className={classes.credits}>Créditos: R$ {credit}</span>
            <Button outline onClick={logOut}>
              Sair
            </Button>
          </div>
        ) : (
          <Button className={classes.login} to="/login">
            Login
          </Button>
        )}
      </Container>
    </nav>
  )
}
