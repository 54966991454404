import React from 'react'

const defaultStyle: React.CSSProperties = {
  width: 'auto',
  border: '1px solid #ABABAB',
  marginTop: '6px',
  marginBottom: '6px'
}

const verticalStyle: React.CSSProperties = {
  border: '1px solid black',
  height: '42px'
}

interface DividerProps {
  className?: string
  style?: React.CSSProperties
  vertical?: boolean
}

export const Divider: React.FC<DividerProps> = ({ className, style, vertical = false }) => {
  if (vertical) {
    return <div className={className} style={{ ...defaultStyle, ...verticalStyle, ...style }} />
  }
  return <div className={className} style={{ ...defaultStyle, ...style }} />
}
