import React from 'react'
import { ReactSVG } from 'react-svg'

import { Container } from 'shared/ui/container/Container'
import { Icon } from 'shared/ui/icon'

import classes from './Footer.module.scss'

const date = new Date()
const currentYear = date.getFullYear()

const FACEBOOK_URL = 'https://www.facebook.com/birdie.com.br/'
const INSTAGRAM_URL = 'https://www.instagram.com/birdie.com.br/'
const YOUTUBE_URL = 'https://www.youtube.com/channel/UCy8z-Fl6BtDazTm9Lg3kE3w'

export const Footer: React.FC = () => (
  <footer className={classes.footer}>
    <Container className={classes.container}>
      <div className={classes.logo}>
        <ReactSVG className={classes.img} src="/img/birdie-logo.svg" />
        <p className={classes.copyright}>&copy; {currentYear}. Todos os direitos reservados.</p>
      </div>
      <a className={classes.item} href={YOUTUBE_URL} rel="noopener noreferrer">
        <Icon.Youtube />
      </a>
      <a className={classes.item} href={FACEBOOK_URL} rel="noopener noreferrer">
        <Icon.Facebook />
      </a>
      <a className={classes.item} href={INSTAGRAM_URL} rel="noopener noreferrer">
        <Icon.Instagram />
      </a>
    </Container>
  </footer>
)
