import React, { useEffect, useState } from 'react'
import { parseISO } from 'date-fns'

import { useAgendamento } from '../../components/context/AgendamentoContext'

import classes from './Schedule.module.scss'

interface ScheduleProps {
  availabilities?: Date[]
  onChange?: (_: Date) => void
}

export const Schedule: React.FC<ScheduleProps> = ({ availabilities, onChange }) => {
  const agendamento = useAgendamento()
  const [value, setValue] = useState<Date>()

  const handleClick = (date: any) => {
    setValue(date)
    onChange && onChange(date)
  }

  useEffect(() => {
    if (availabilities?.length) {
      if (agendamento.data && agendamento.hour) {
        const date = parseISO(`${agendamento.data} ${agendamento.hour}`)
        setValue(date)
      }
    }
  }, [agendamento, availabilities])

  return (
    <div className={classes.root}>
      <div className="container-fluid">
        <div className={classes.buttonRow}>
          {availabilities &&
            availabilities.map((item, index) => {
              const active =
                new Date(value!).getHours() === new Date(item).getHours() &&
                new Date(value!).getMinutes() === new Date(item).getMinutes()

              return (
                <div key={index}>
                  <div className={active ? classes.active : classes.item} onClick={() => handleClick(item)}>
                    {new Date(item).toTimeString().slice(0, 5)}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
