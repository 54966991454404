import React, { useState } from 'react'
import InputMask from 'react-input-mask'

import { Wrapper } from '../Wrapper'
import { Text } from '../Text'
import { Form } from '../Form'
import { Label } from '../Label'
import { Input } from '../Input'
import { Checkbox } from '../Checkbox'

const ProfFinan = (props: any) => {
  const { user } = props

  const [is_me, setis_me] = useState(false)
  const [, setName] = useState('')
  const [, setCpf] = useState('')
  const [, setEmail] = useState('')

  const changeis_me = () => {
    user.is_me = !is_me
    setis_me(!is_me)
  }

  const changeName: (event: React.ChangeEvent<HTMLInputElement>) => void = e => {
    user.name = e.target.value
    setName(e.target.value)
  }
  const changeCpf: (event: React.ChangeEvent<HTMLInputElement>) => void = e => {
    user.cpf = e.target.value
    setCpf(e.target.value)
  }
  const changeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void = e => {
    user.email = e.target.value
    setEmail(e.target.value)
  }

  const isEmailvalid = () => user?.email?.match(/^(\w+@\w+\.\w+)/g) !== null ?? false

  return (
    <Wrapper className="users">
      <Text className="users">Represetante Financeiro</Text>

      <Form>
        <Checkbox>
          <Input name="represent" onChange={changeis_me} type="checkbox" /> Eu sou o representante financeiro
        </Checkbox>
        <Label className={is_me ? 'hidden' : ''}>
          Nome completo
          <Input type="name" onChange={changeName} name="name" placeholder="nome" />
        </Label>
        <Label className={is_me ? 'hidden' : ''}>
          CPF
          <InputMask mask="999.999.999-99" onChange={changeCpf}>
            <Input type="text" name="cpf" placeholder="CPF" />
          </InputMask>
        </Label>
        <Label className={is_me ? 'hidden' : ''}>
          E-mail
          <Input
            type="email"
            className={isEmailvalid() ? '' : 'invalid'}
            onChange={changeEmail}
            name="email"
            placeholder="e-mail"
          />
        </Label>
      </Form>
    </Wrapper>
  )
}

export default ProfFinan
