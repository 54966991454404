import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { useProfileContext } from 'hooks/ProfileContext'

import { AgendamentoContextProvider } from './components/context/AgendamentoContext'
import { SessaoContextProvider } from './components/context/SessaoContext'
import { Navbar } from './components/Navbar'

import { CalendarPage as Calendar } from './pages/Calendar'
import { Confirmation } from './pages/Confirmation'
import { Finish } from './pages/Finish'
import { Information } from './pages/Information'

import { Container } from 'shared/ui/container/Container'

import classes from './Scheduling.module.scss'

export const Scheduling: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [profile] = useProfileContext()
  return (
    <SessaoContextProvider token={match.params.id}>
      <AgendamentoContextProvider>
        {!profile && <Navbar />}
        <Container>
          <div className={classes.container}>
            <Switch>
              <Route path={`${match.path}/confirmacao`} component={Confirmation} />
              <Route path={`${match.path}/finalizacao`} component={Finish} />
              <Route path={`${match.path}/informacoes`} component={Information} />
              <Route path={match.path} exact component={Calendar} />
            </Switch>
          </div>
        </Container>
      </AgendamentoContextProvider>
    </SessaoContextProvider>
  )
}
