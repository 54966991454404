import React from 'react'
import { ReactSVG } from 'react-svg'

import { classNames } from 'shared/util/class-names'

import classes from './PaymentService.module.scss'

interface PaymentServiceProps {
  current?: Model.Service
  onClick: (_: Model.Service) => void
  service: Model.Service
}

export const PaymentService: React.FC<PaymentServiceProps> = ({ current, onClick, service }) => {
  const className = classNames(classes.service, current === service && classes.active)
  const handleClick = () => onClick(service)
  return (
    <div className={className} onClick={handleClick}>
      <div className={classes.icons}>
        {service.products
          .filter(item => item.item)
          .map(item => (
            <ReactSVG key={item.id} src={item.item!.icon} />
          ))}
      </div>
      <div className={classes.labels}>
        <h3>
          {service.name}{' '}
          {service.rules?.[0]?.discount && (
            <span className={classes.discount}>{service?.rules?.[0].discount.title}</span>
          )}
        </h3>
      </div>
    </div>
  )
}
