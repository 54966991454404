import React from 'react'
import { TableColumn } from 'shared/table'

export const Header = () => {
  return (
    <>
      <TableColumn size={0.7}>ID</TableColumn>
      <TableColumn align="flex-start">Referência</TableColumn>
      <TableColumn align="flex-start" size={2}>
        Ensaio
      </TableColumn>
      <TableColumn align="flex-start" size={3}>
        Endereço
      </TableColumn>
      <TableColumn align="flex-start">Criação</TableColumn>
      <TableColumn align="flex-start" size={2}>
        Entrega
      </TableColumn>
    </>
  )
}
