import React from 'react'
import { format, parseISO } from 'date-fns'

import { CancelSessionModal } from 'shared/components/sessions/CancelSessionModal'
import { useModal } from 'shared/modal'
import { parseAddressComponents } from 'shared/util/google-maps'

import { SessionProductIcon } from 'shared/components/sessions/SessionProductIcon'

import classes from './SessionViewInfo.module.scss'
import { Button } from 'shared/ui/button/Button'
import { SessionStatus } from 'shared/enum/session/SessionStatus'
import { useProfileContext } from 'hooks/ProfileContext'
import { getProfile } from 'services/profile'

interface SessionViewInfoProps {
  onChange: () => void
  session: Resource.SessionResource
}

export const SessionViewInfo: React.FC<SessionViewInfoProps> = ({ onChange, session }) => {
  const address = parseAddressComponents(session.address?.address_components)
  const [, setProfile] = useProfileContext()
  const cancelModal = useModal(CancelSessionModal)

  const handleCancel = (event: React.MouseEvent) => {
    event.preventDefault()
    cancelModal.open({
      onChange,
      session,
      onClosed: () => {
        getProfile().then(setProfile)
      }
    })
  }

  const sessionStatus = () => {
    if (session.status === SessionStatus.CANCELED) {
      return <p className={classes.info}>Ensaio Cancelado</p>
    }

    if (session.status === SessionStatus.DELIVERED) return null

    return (
      <div>
        <div className={classes.actions}>
          <Button className={classes.cancel} onClick={handleCancel} outline>
            Cancelar esta sessão
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        {session.client.logo_url && (
          <div className={classes.image_logo}>
            <img
              src={
                session.client.logo_url.match(/((http)+(s)*)/g) === null
                  ? 'https://assets.birdie.com.br/' + session.client.logo_url
                  : session.client.logo_url
              }
              alt="Logo"
            />
          </div>
        )}
        <p className={classes.title}>{session.client.name}</p>
        <p className={classes.id}>ID {session.id}</p>
        <div className={classes.icons}>
          {session.service.products
            .sort((p: any, p2: any) => (p.item.id > p2.item.id ? 1 : -1))
            .map((product, i) => (
              <SessionProductIcon key={i} className={classes.itemIcon} url={product.item.icon} />
            ))}
        </div>
      </div>
      <div className={classes.main}>
        <p className={classes.info}>
          Criado em {format(parseISO(session.created_at), 'dd/MM/yyyy')} por {session.user.name}
        </p>
        <p className={classes.info}>
          {address?.get('route')}
          {address?.get('street_number') && `, ${address?.get('street_number')}`} {session.vertical_data?.unit}/
          {session.vertical_data?.complement} -{' '}
        </p>
        <p className={classes.info}>
          {address?.get('sublocality')} | {address?.get('administrative_area_level_2')}
        </p>
        <p className={classes.info}>{address?.get('administrative_area_level_1')}</p>
      </div>
      {sessionStatus()}
    </div>
  )
}
