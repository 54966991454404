import styled from 'styled-components'

export const Input = styled.input`
  font-size: 16px;
  line-height: 19px;
  padding: 10px 15px;
  border: 1px solid #707070;
  border-radius: 6px;
  margin: 10px 0;

  &.error {
    border-color: red !important;
  }

  &.invalid {
    border: 1px solid red;
  }
`
