import React from 'react'
import { Link } from 'react-router-dom'

import Steps from '../../../components/Steps'
import Card from '../../../components/Welcome/Card'
import Btn from '../../../components/Button'
import Img from '../../../assets/images/side-image.png'

import { Wrapper } from '../../../components/Wrapper'
import { Main } from '../../../components/Main'
import { Before } from '../../../components/Before'
import { Content } from '../../../components/Content'
import { Call } from '../../../components/Call'
import { Text } from '../../../components/Text'

const Welcome = () => (
  <Wrapper className="welcome">
    <Main>
      <Before>
        <img src={Img} alt="sofa" />
      </Before>
      <Content>
        <Steps className={'welcome'} />

        <Call>Conheça a Birdie!</Call>
        <Text className="welcome">Agora que já conversamos, conheça mais sobre a plataforma Birdie.</Text>

        <Card />

        <Link to="/onboard/password">
          <Btn>Avançar →</Btn>
        </Link>
      </Content>
    </Main>
  </Wrapper>
)

export default Welcome
