import styled from 'styled-components'

export const Checkbox = styled.label`
  font-size: 16px;
  line-height: 19px;
  margin: 10px 0;

  a {
    text-decoration: underline;
    color: #0aa0d2;
  }
`
