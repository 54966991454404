import React from 'react'

import Hero from './components/Hero'
import NextSteps from './components/NextSteps'

export const Register: React.FC = () => {
  return (
    <div className="wrapper">
      <Hero />
      <NextSteps />
    </div>
  )
}
