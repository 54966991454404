import React from 'react'
import { VerticalCars } from './Cars'
import { VerticalHousings } from './Housing'

enum VerticalType {
  HOUSING = 1,
  CARS = 2
}

interface VerticalProps {
  verticalId?: VerticalType
  onChange?: (value: any) => void
  value?: any
  readonly?: boolean
  vision?: 'admin' | 'client' | undefined
}

export const Vertical: React.FC<VerticalProps> = ({
  verticalId,
  onChange,
  value,
  readonly = false,
  vision = 'admin'
}) => {
  if (verticalId === undefined) {
    return <></>
  }

  if (verticalId === VerticalType.HOUSING) {
    return <VerticalHousings onChange={onChange} value={value} readonly={readonly} vision={vision} />
  }

  if (verticalId === VerticalType.CARS) {
    return <VerticalCars onChange={onChange} value={value} readonly={readonly} />
  }

  throw new Error('verticalId not defined.')
}
