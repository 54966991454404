import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Password from './components/Password'
import { Container } from 'shared/ui/container/Container'
import { Card } from 'shared/ui/card/Card'

import classes from './Profile.module.scss'

export default () => {
  return (
    <Container className={classes.container}>
      {/* <Container className={classes.navigator}>
        <Card>
          <NavLink to="/profile/info">Informações</NavLink>
        </Card>
        <Card>
          <NavLink to="/profile/password">Senha</NavLink>
        </Card>
      </Container> */}
      <Card className={classes.content}>
        <Switch>
          {/* <Route path="/profile/info" component={Profile} /> */}
          <Route path="/profile/password" component={Password} />

          <Redirect to="/profile/password" />
        </Switch>
      </Card>
    </Container>
  )
}
