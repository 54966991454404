import React, { FC, useEffect } from 'react'

import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'

import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { useSessaoContext } from '../components/context/SessaoContext'
import { useAgendamentoContext } from '../components/context/AgendamentoContext'

import classes from './Finish.module.scss'
import { parseAddress } from 'shared/util/google-maps'
import { ReactSVG } from 'react-svg'
import { useProfileContext } from 'hooks/ProfileContext'
import { getProfile } from 'services/profile'

export const Finish: FC = () => {
  const [session] = useSessaoContext()
  const [agendamento] = useAgendamentoContext()
  const [profile, setProfile] = useProfileContext()

  const address = parseAddress(session?.address?.address_components)

  useEffect(() => {
    if (profile) {
      getProfile().then(setProfile)
    }
  }, [profile, setProfile])

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        <Icon icon={faCheck} />
        Ensaio agendado com sucesso!
      </h2>
      <Card shadow>
        <div>
          {agendamento.data && (
            <h3>
              {format(new Date(`${agendamento.data} ${agendamento.hour}`), 'EEEE, dd/LL HH:mm', { locale: ptBR })}
            </h3>
          )}
          {session?.provider && <h3>Fotógrafo(a): {session.provider.name}</h3>}
        </div>
        <div className={classes.divider} />
        <div>
          <h3>{session?.service?.name}</h3>
          <div className={classes.listProducts}>
            {session?.service?.products?.map(({ item }: any, index: number) => {
              let icon = 'https://assets.birdie.com.br/' + item.icon

              return (
                <div className={classes.product} key={index}>
                  <ReactSVG className={classes.productIcon} src={icon} />
                  <p className={classes.productTitle}>{item.title}</p>
                </div>
              )
            })}
          </div>
        </div>

        <div>
          <h3>Local</h3>
          <div>
            <p>
              {address.get('street')}
              {address.has('number') && `, ${address.get('number')}`}
            </p>
            {session.vertical_data?.unit && <p>Unidade: {session.vertical_data.unit}</p>}
            {session.vertical_data?.complement && <p>Complemento: {session.vertical_data.complement}</p>}
            <p>
              {address.get('neighborhood')} - {address.get('city')}, {address.get('state')}
            </p>
          </div>
        </div>

        {/* TODO: get extra information of each product here
        <div>
          <h3>tour Virtual 1</h3>
          <div>
            <span>10025 - Unidade 15/B - 100m²</span>
          </div>
        </div> */}

        {session?.responsible_information && (
          <div>
            <h3>Responsável</h3>
            <div>
              {session?.responsible_information.company && <p>{session?.responsible_information.company}</p>}
              <p>Nome: {session?.responsible_information.name}</p>
              <p>
                {session?.responsible_information.phone} - {session?.responsible_information.email}
              </p>
            </div>
          </div>
        )}

        <div>
          <h3>Quem receberá o fotógrafo</h3>
          <div>
            <span>{agendamento?.owner?.name}</span>
            <span>
              {agendamento?.owner?.phone} - {agendamento?.owner?.email}
            </span>
            {session?.vertical === 1 && <span>Chaves: {agendamento?.keys}</span>}
            {agendamento.extrainfo && <p>Observações: {agendamento?.extrainfo}</p>}
          </div>
        </div>
      </Card>
      <Button to="/">OK!</Button>
    </div>
  )
}
