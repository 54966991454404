import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { useClientServices } from 'shared/hooks/data/services'
import { useFilterContext } from '../../hooks/filters'
import { Label } from 'components/Label'
import { useProfileContext } from 'hooks/ProfileContext'

import classes from './Filter.module.scss'

const animatedComponents = makeAnimated()

function map(services: any[]) {
  return services?.map(service => {
    return {
      value: service.id,
      label: service.name
    }
  })
}

export const Services = () => {
  const [profile] = useProfileContext()
  const services = useClientServices(profile?.client.id!)
  const [, filter] = useFilterContext()

  const options = map(services!)

  const onChange = (selected: any) => filter('services', selected?.map((select: any) => select.value) ?? null)

  return (
    <Label className={classes.input}>
      <p>Serviço</p>
      <Select
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'black'
          }
        })}
        placeholder={'Selecione um Serviço'}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        options={options}
      />
    </Label>
  )
}
