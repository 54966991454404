import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Form, FormGroup, FormText } from 'shared/form'
import { FormMasked } from 'shared/form/FormMasked'
import { Button } from 'shared/ui/button/Button'
import { toFormData } from 'shared/util/form-data'

import classes from './Form.module.scss'
import { FormAddress } from 'shared/form/FormAddress'

export default () => {
  const history = useHistory()
  const [client, setClient] = useState<any>({})

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const requestProps: RequestInit = {
      body: toFormData(client),
      method: 'POST'
    }

    fetch('/api/new-client', requestProps).then(res => {
      if (res.ok) {
        history.push('/pre-cadastro/obrigado')
      } else {
        history.push('/pre-cadastro/erro')
      }
    })
  }

  return (
    <section className={classes.form}>
      <h2 className={classes.title}>Eu quero!</h2>
      <Form onSubmit={onSubmit}>
        <FormGroup label="Nome*">
          <FormText required onChange={name => setClient((client: any) => ({ ...client, name }))} />
        </FormGroup>
        <FormGroup label="Telefone*">
          <FormMasked mask="phone" required onChange={phone => setClient((client: any) => ({ ...client, phone }))} />
        </FormGroup>
        <FormGroup label="E-mail*">
          <FormText type="email" required onChange={email => setClient((client: any) => ({ ...client, email }))} />
        </FormGroup>
        <FormGroup label="Empresa*">
          <FormText required onChange={company => setClient((client: any) => ({ ...client, company }))} />
        </FormGroup>
        <FormGroup label="Cidade*">
          <FormAddress
            required
            type="cities"
            onChange={address => setClient((client: any) => ({ ...client, address }))}
          />
        </FormGroup>
        <FormGroup label="Quantidade de funcionários">
          <FormText
            type="number"
            required
            onChange={employees_qnt => setClient((client: any) => ({ ...client, extra_data: { employees_qnt } }))}
          />
        </FormGroup>
        <Button variant="secondary" type="submit">
          PROSSEGUIR &#8594;
        </Button>
      </Form>
      <a
        href="/login"
        style={{
          textAlign: 'center',
          display: 'block',
          marginTop: '1.25rem',
          color: 'black',
          fontSize: '1rem',
          fontWeight: 600
        }}>
        Já tem um cadastro? Entre aqui!
      </a>
      <div className={classes.information}>
        <p>Precisa de mais do que 5 tours por mês?</p>
        <p>Espaços acima de 1200m²?</p>
        <p>Outras regiões?</p>
        <Button
          href="https://api.whatsapp.com/send?phone=5511937309960&text=Ol%C3%A1!%20Gostaria%20de%20realizar%20uma%20cota%C3%A7%C3%A3o%20para%20"
          outline>
          Fale com nosso time comercial :)
        </Button>
      </div>
    </section>
  )
}
