import { Footer } from 'core/footer/Footer'
import { Navbar } from 'pages/scheduling/components/Navbar'
import React from 'react'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'

import classes from './Register.module.scss'

export const Thanks: React.FC = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Card variant="light" shadow className={classes.card}>
          <h1>Obrigado pelo seu cadastro!</h1>
          <span>Verifique seu e-mail para acessar a plataforma! :)</span>
        </Card>
      </Container>
      <Footer />
    </>
  )
}
