import React, { useState } from 'react'
import InputMask from 'react-input-mask'

import { Wrapper } from '../Wrapper'
import { Text } from '../Text'
import { Form } from '../Form'
import { Label } from '../Label'
import { Input } from '../Input'

const ProfUser = (props: any) => {
  const { user, removeUser } = props

  const [, setName] = useState('')
  const [, setCpf] = useState('')
  const [, setEmail] = useState('')

  const isEmailvalid = () => user?.email?.match(/^(\w+@\w+\.\w+)/g) !== null ?? false

  const changeName: (event: React.ChangeEvent<HTMLInputElement>) => void = e => {
    setName(e.target.value)
    user.name = e.target.value
  }
  const changeCpf: (event: React.ChangeEvent<HTMLInputElement>) => void = e => {
    setCpf(e.target.value)
    user.cpf = e.target.value
  }
  const changeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void = e => {
    setEmail(e.target.value)
    user.email = e.target.value
  }

  return (
    <Wrapper className="users">
      <Text className="users">Usuário</Text>

      <Form>
        <Label>
          Nome completo
          <Input type="text" autoFocus={true} onChange={changeName} name="name" placeholder="nome" />
        </Label>
        <Label>
          CPF
          <InputMask mask="999.999.999-99" onChange={changeCpf}>
            <Input type="text" name="cpf" placeholder="CPF" />
          </InputMask>
        </Label>
        <Label>
          E-mail
          <Input
            type="email"
            className={isEmailvalid() ? '' : 'invalid'}
            onChange={changeEmail}
            name="email"
            placeholder="e-mail"
          />
        </Label>
        <a href="#last-user" onClick={removeUser}>
          Remover usuário
        </a>
      </Form>
    </Wrapper>
  )
}

export default ProfUser
