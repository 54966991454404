import React, { useState } from 'react'
import styled from 'styled-components'

import AddIcon from '../../assets/images/Icon-Add.svg'

import { useProfileContext } from '../../hooks/ProfileContext'

const Card = styled.div``

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 25px;
  margin: 0 0 5px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Name = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #000;
`

const Add = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LittleText = styled.p`
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  color: #7f7f7f;
  margin: 0 0 35px;
`

const Upload = styled.input`
  width: 160px;
  height: 35px;

  position: relative;
  display: block;

  &:before {
    content: '';
    display: inline-block;
    background: url('${AddIcon}');
    width: 35px;
    height: 35px;
  }

  &:after {
    content: 'Adicionar logo';
    position: absolute;
    font-weight: normal;
    margin-left: 5px;
    font-size: 16px;
    line-height: 35px;
    height: 35px;
    width: 115px;
    text-align: left;
    color: #0aa0d2;
    right: 0;
  }

  &::-webkit-file-upload-button {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
  }

  span {
    display: none;
  }
`

const DisplayLogo = styled.img`
  height: 70px;
  margin: 0 15px 0 0;
`

const CallProfile = (props: any) => {
  const [profile] = useProfileContext()
  const [, setLogo] = props?.logo
  const [preview, setPreview] = useState<any>('')

  const onLogoChange: (event: React.ChangeEvent<HTMLInputElement>) => void = e => {
    const file = e.target.files![0]
    if (file) {
      setLogo(file)
      const reader = new FileReader()
      reader.onloadend = () => setPreview(reader.result)
      reader.readAsDataURL(file)
    }
  }

  return (
    <Card>
      <Wrapper>
        <Name>{profile?.client?.name}</Name>
        <Add>
          {preview == null ? '' : <DisplayLogo src={preview} />}
          <Upload type="file" accept="image/*" onChange={onLogoChange} />
        </Add>
      </Wrapper>
      <LittleText>Formato recomendado: arquivo png (fundo transparente) com tamanho mínimo de 300 x 300px.</LittleText>
    </Card>
  )
}

export default CallProfile
