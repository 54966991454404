import React from 'react'
import styled from 'styled-components'

import Services from './Services'
import { Wrapper } from '../Wrapper'

import { useProfileContext } from '../../hooks/ProfileContext'
import { useClientServices } from 'shared/hooks/data/services'

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  flex: 1;
`

const Name = styled.p`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin: 0 0 4px;
`

const Status = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 2px;
`

const Number = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 2px;
`

const Title = styled.h3`
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 16px;
  text-transform: uppercase;
`

const ServiceItems = styled.div`
  margin: 0 0 15px 0;
`

const Card = () => {
  const [profile] = useProfileContext()
  const services = useClientServices()

  return (
    <Wrapper className="welcome-card">
      <Header>
        <Name>{profile?.name}</Name>
        <Status>{profile?.client?.name}</Status>
        <Number>{profile?.phone}</Number>
      </Header>

      <ServiceItems>
        {services?.map((service: any, index: number) => {
          return (
            <div key={index}>
              <Title>{service.name}</Title>
              <Services items={service.products} />
            </div>
          )
        })}
      </ServiceItems>
    </Wrapper>
  )
}

export default Card
