const api = async <T extends {}>(url: string, reqInit?: RequestInit, useJson = true): Promise<Response<T>> => {
  let headers = reqInit?.headers

  if (useJson) {
    headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })
  }

  const init: RequestInit = { headers, ...reqInit! }

  const res = await fetch(url, init)
  return res.json()
}

export default api
