import { useEffect, useState } from 'react'

export function useServices() {
  const [services, setServices] = useState<any[]>()
  useEffect(() => {
    fetch(`/api/services`)
      .then(response => response.json())
      .then(({ data }) => setServices(data))
  }, [])
  return services
}

export function useClientServices(clientId?: number | undefined) {
  const [services, setServices] = useState<any[]>()
  useEffect(() => {
    fetch(`/api/client/services`)
      .then(response => response.json())
      .then(({ data }) => setServices(data))
  }, [clientId])
  return services
}
