import React, { Fragment, useState } from 'react'

import { FormAddress } from 'shared/form/FormAddress'
import { FormGroup } from 'shared/form/FormGroup'
import { FormText } from 'shared/form/FormText'
import { CardGrey } from 'shared/ui/card/CardGrey'
import { Button } from 'shared/ui/button/Button'
import { parseAddress } from 'shared/util/google-maps'

import { Stepper } from 'components/Stepper'

import classes from './BasicInfo.module.scss'

interface BasicInfoValue {
  address: google.maps.places.PlaceResult
  client: Model.Client
  costCenter: Model.CostCenter
  service: Model.Service
}

export const BasicInfo = ({ history, state: [sessions, setSessions] }: any) => {
  const [value, setValue] = useState<Partial<BasicInfoValue>>({})
  const address = parseAddress(value.address?.address_components)
  const handleSession = (k: number) => (key: string) => (value: string) => {
    setSessions((sessions: any) =>
      sessions.map((session: any, i: number) => {
        if (k === i) {
          const vertical_data = Object.assign(session.vertical_data || {}, { [key]: value })
          return Object.assign({}, session, { vertical_data })
        } else {
          return session
        }
      })
    )
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setSessions([
      ...sessions.map((session: any) => ({
        ...session,
        ...value
      }))
    ])
    history.push('/sessions/add/responsible')
  }
  return (
    <div>
      <Stepper count={4} current={1} />
      <form onSubmit={handleSubmit}>
        <h1>Informações</h1>
        <FormGroup label="Endereço">
          <FormAddress onChange={address => setValue(value => ({ ...value, address }))} required />
          {address.size > 0 && (
            <>
              <FormGroup label="CEP">
                <FormText required value={address.get('postal_code')} />
              </FormGroup>
              <FormGroup label="Número">
                <FormText required value={address.get('number')} />
              </FormGroup>
              <FormGroup label="Bairro">
                <FormText required value={address.get('neighborhood')} />
              </FormGroup>
            </>
          )}
        </FormGroup>
        {sessions.map((data: any, i: number) => (
          <Fragment key={i}>
            <h2>Ensaio</h2>
            <CardGrey>
              <div className={classes.session}>
                <FormGroup label="Unidade">
                  <FormText onChange={handleSession(i)('unit')} value={data.unit?.toString()} />
                </FormGroup>
                <FormGroup label="Bloco/Torre/Quadra">
                  <FormText onChange={handleSession(i)('complement')} value={data.complement?.toString()} />
                </FormGroup>
                <FormGroup label="Área">
                  <FormText required onChange={handleSession(i)('area')} value={data.area?.toString()} />
                </FormGroup>
                <FormGroup label="Ref. do imóvel">
                  <FormText onChange={handleSession(i)('reference')} value={data.reference?.toString()} />
                </FormGroup>
              </div>
            </CardGrey>
          </Fragment>
        ))}
        <Button size="lg">AVANÇAR →</Button>
      </form>
    </div>
  )
}
