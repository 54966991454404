import styled from 'styled-components'

export const Before = styled.div`
  width: 0;
  margin: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 300ms ease-in-out;

  @media only screen and (min-width: 960px) {
    width: 250px;
    margin-right: 30px;
    visibility: visible;
  }
`
