import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import PlantaBaixa from '../../assets/images/Index/Carousel/planta-baixa.png'
import FotosProfissionais from '../../assets/images/Index/Carousel/fotos-profissionais.png'
import Tags from '../../assets/images/Index/Carousel/tags.png'

export default () => (
  <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={5000} showArrows={false} emulateTouch>
    <div>
      <img alt="" src={PlantaBaixa} />
      <p className="title">Planta baixa</p>
      <p className="text">
        O tour virtual permite visualizar a planta baixa mobiliada do imóvel. Você tem a opção de solicitar a planta
        baixa com medidas.
      </p>
    </div>
    <div>
      <img alt="" src={FotosProfissionais} />
      <p className="title">Fotos profissionais</p>
      <p className="text">
        A partir do tour virtual são geradas fotos de alta definição com luz, nitidez e enquadramento de alto padrão
        profissionais.
      </p>
    </div>
    <div>
      <img alt="" src={Tags} />
      <p className="title">Tags</p>
      <p className="text">
        Ao longo da navegação o usuário pode interagir com o ambiente através das tags de destaque. É possível incluir
        textos, fotos, vídeos e links úteis. Destaque o que realmente interessa ao seu público.
      </p>
    </div>
  </Carousel>
)
