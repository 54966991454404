import React from 'react'

import { FormInputGroup, FormInputGroupAppend } from 'shared/form/FormInputGroup'
import { FormText } from 'shared/form/FormText'
import { Button } from 'shared/ui/button/Button'
import { useRef } from 'react'

export const Scheduling = ({ state: [sessions] }: any) => {
  const ref = useRef<HTMLInputElement>(null)

  const copyToClipboard = () => {
    if (ref && ref.current) {
      /* Select the text field */
      ref.current.select()
      ref.current.setSelectionRange(0, 99999) /*For mobile devices*/

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(ref.current.value)
    }
  }

  return (
    <div>
      <h1>Agendamento de ensaio</h1>
      <section>
        {sessions.map(
          (
            { token = '', vertical_data: { area = '', complement = '', reference = '', unit = '' } = {} }: any,
            i: number
          ) => (
            <div key={i}>
              <h3>Ensaio solicitado</h3>
              <p>
                Ref: {reference} - Unidade {unit}/{complement} - {area}m<sup>2</sup>
              </p>
              <FormInputGroup>
                <FormText ref={ref} readOnly value={`https://${document.location.host}/calendar/${token}`} />
                <FormInputGroupAppend>
                  <Button to={`/calendar/${token}`}>Abrir</Button>
                  <Button onClick={copyToClipboard}>Copiar</Button>
                </FormInputGroupAppend>
              </FormInputGroup>
              <p>Você pode agendar o ensaio agora ou compartilhar o link para o responsável pelo ensaio agendar.</p>
            </div>
          )
        )}
      </section>
    </div>
  )
}
