import React from 'react'

import classes from './Pagination.module.scss'
import * as P from 'shared/ui/pagination/Pagination'
import { usePaginationContext } from '../../hooks/pagination'

export default () => {
  const [{ lastPage, page }, setPagination] = usePaginationContext()

  if (lastPage < 2) {
    return <></>
  }

  const previousPage = () => {
    setPagination('previous')
  }
  const nextPage = () => {
    setPagination('next')
  }
  const selectPage = (page: number) => {
    setPagination('select', page)
  }

  return (
    <>
      <div className={classes.listPagination}>
        <P.PaginationList className={classes.listPagination}>
          <P.ItemPrevious onClick={previousPage}>&lsaquo;</P.ItemPrevious>
          {Array.from({ length: lastPage < 5 ? lastPage : 5 }, (_, k) => {
            if (page < 4) {
              return k + 1
            }
            if (lastPage - page < 2) {
              return k + page - 4 + lastPage - page
            }

            return k + page - 2
          }).map(index => (
            <P.ItemPagination key={index} onClick={() => selectPage(index)} className={index === page ? 'active' : ''}>
              {index}
            </P.ItemPagination>
          ))}
          <P.ItemFowards onClick={nextPage}>&rsaquo;</P.ItemFowards>
        </P.PaginationList>
      </div>
    </>
  )
}
