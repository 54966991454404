import React from 'react'
import styled from 'styled-components'

import { Navbar } from 'core/navbar/Navbar'
import Bg from '../../assets/images/wallpaper.png'

const MyHeader = styled.header`
  margin: 0 0 40px;
  padding: 0;
  width: 100%;
  transition: all 300ms ease-in-out;

  @media only screen and (min-width: 540px) {
    margin: 0 0 60px;
  }
`

const Wrapper = styled.div`
  display: block;
  max-width: 100%;
  width: 100%;
  margin: -40px 0 0;
  transition: all 300ms ease-in-out;

  @media only screen and (min-width: 540px) {
  }

  @media only screen and (min-width: 720px) {
    width: 98%;
    margin: 0 auto;
  }

  @media only screen and (min-width: 960px) {
    width: 96%;
  }

  @media only screen and (min-width: 1124px) {
    width: 94%;
    display: block;
    max-width: 1175px;
  }
`

const Img = styled.img`
  max-width: 100%;
  height: auto;
`

const Callout = styled.div`
  position: relative;
  margin: -47px auto 0px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  transition: all 300ms ease-in-out;

  @media only screen and (min-width: 540px) {
    background: white;
    backdrop-filter: unset;
    margin: -54px auto 0px;
    min-width: 490px;
    max-width: 96%;
  }

  @media only screen and (min-width: 720px) {
    margin: -62px auto 0px;
    min-width: 640px;
    max-width: 94%;
  }

  @media only screen and (min-width: 960px) {
    margin: -67px auto 0px;
    min-width: 890px;
    max-width: 92%;
  }

  @media only screen and (min-width: 1124px) {
    margin: -80px auto 0px;
    max-width: 1024px;
  }
`

const Text = styled.h1`
  font-size: 20px;
  text-align: left;
  margin: 0 auto 0 0;
  box-sizing: border-box;
  width: 320px;
  padding: 24px 30px;
  transition: all 300ms ease-in-out;

  @media only screen and (min-width: 540px) {
    font-size: 26px;
    width: 380px;
  }

  @media only screen and (min-width: 720px) {
    font-size: 32px;
    width: 520px;
  }

  @media only screen and (min-width: 960px) {
    font-size: 36px;
    width: 580px;
  }

  @media only screen and (min-width: 1124px) {
    font-size: 48px;
    width: 740px;
  }
`

const Header = () => (
  <MyHeader>
    <Navbar />

    <Wrapper>
      <Img src={Bg} alt="Wallperper" />
      <Callout>
        <Text>Solicitar um ensaio com a Birdie não tem mistério!</Text>
      </Callout>
    </Wrapper>
  </MyHeader>
)

export default Header
