import React from 'react'

import logo from '../../assets/logo1.svg'

import classes from './Hero.module.scss'

import Cost from '../Cost'
import Form from '../Form'

export default () => {
  return (
    <>
      <div className={classes.heroBack}></div>
      <section className={classes.hero}>
        <div className={classes.heroTitle}>
          <img src={logo} width="120px" height="120px" alt="logo" />
          <h1>Agende imagens incríveis em poucos cliques</h1>
          <h2>
            Cadastre-se gratuitamente, personalize seu serviço e tenha acesso à poderosa ferramenta de apresentação de
            ambientes da Birdie.
          </h2>
          <Cost />
        </div>

        <Form />
      </section>
    </>
  )
}
