import React from 'react'
import { Table, TableBody, TableHeader } from 'shared/table'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'
import { useInfoContext } from '../hooks/info'
import { Filter } from '../components/Filter/Filter'
import Pagination from '../components/Pagination/Pagination'

import { Header } from '../components/Table/Header'
import { Rows } from '../components/Table/Rows'

import classes from './SessionList.module.scss'
import { useMetaContext } from '../hooks/meta'
import { useEffect } from 'react'

export const SessionList = () => {
  const [info] = useInfoContext()
  const [meta] = useMetaContext()

  useEffect(() => {
    meta('update')
  }, [meta])

  return (
    <Container className={classes.container}>
      <Card className={classes.card} shadow>
        <h2>Filtrar ensaios</h2>
        <Filter />
      </Card>
      <Card className={classes.card} shadow>
        <div className={classes.row}>
          <div className={classes.box}>
            <div className={classes.total}>
              <p>{info?.totalSessions}</p>
              <p>ENSAIOS TOTAIS</p>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.scheduled}>
              <p>{info?.totalScheduleds}</p>
              <p>ENSAIOS AGENDADOS</p>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.pending}>
              <p>{info?.totalPendings}</p>
              <p>ENSAIOS PENDENTES</p>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.delivered}>
              <p>{info?.totalDelivereds}</p>
              <p>ENSAIOS ENTREGUES</p>
            </div>
          </div>
        </div>
      </Card>
      <Card className={classes.card} shadow>
        <Table strip>
          <TableHeader>
            <Header />
          </TableHeader>
          <TableBody>
            <Rows />
          </TableBody>
        </Table>
        <Pagination />
      </Card>
    </Container>
  )
}
