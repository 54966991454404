import React from 'react'

import classes from './SessionViewInfo.module.scss'
import { classNames } from 'shared/util/class-names'

interface ProviderProps {
  session: Resource.SessionResource
}

export const Provider: React.FC<ProviderProps> = ({ session }) => {
  // don't show this component without a session at its props
  if (!session || !session.provider) {
    return <></>
  }

  return (
    <div className={classNames(classes.card, classes.center)}>
      <h2>Fotógrafo</h2>
      {session.provider.user.profile_image_path && (
        <div className={classes.image_logo}>
          <img src={session.provider.user.profile_image_path} alt="provider" />
        </div>
      )}
      <span>{session.provider.name}</span>
    </div>
  )
}
