import React, { FC, FormEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { Stepper } from 'components/Stepper'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

import { useAgendamentoContext } from '../components/context/AgendamentoContext'
import { useSessaoContext } from '../components/context/SessaoContext'

import classes from './Information.module.scss'
import { FormCheck, FormGroup, FormText } from 'shared/form'
import { FormTextArea } from 'shared/form/FormTextArea'
import { parseAddress } from 'shared/util/google-maps'
import { Button } from 'shared/ui/button/Button'
import { FormMasked } from 'shared/form/FormMasked'

import { useProfileContext } from 'hooks/ProfileContext'

export const Information: FC = () => {
  const history = useHistory()
  const [session] = useSessaoContext()
  const [agendamento, setAgendamento] = useAgendamentoContext()
  const [profile] = useProfileContext()
  const address = parseAddress(session?.address?.address_components)

  const handleBack = () => {
    history.go(-1)
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  const handleOwnerInputAgendamento = (key: string, input: any) => {
    setAgendamento((agendamento: any) => ({ ...agendamento, owner: { ...agendamento.owner, [key]: input } }))
  }

  const handleInputAgendamento = (key: string, input: any) => {
    setAgendamento((agendamento: any) => ({ ...agendamento, [key]: input }))
  }

  const handleNext = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    history.push('confirmacao')
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  const handleResponsible = (isResponsible: boolean) => {
    if (isResponsible) {
      handleOwnerInputAgendamento('email', profile?.email)
      handleOwnerInputAgendamento('name', profile?.name)
      handleOwnerInputAgendamento('phone', profile?.phone)
    } else {
      handleOwnerInputAgendamento('email', '')
      handleOwnerInputAgendamento('name', '')
      handleOwnerInputAgendamento('phone', '')
    }
  }

  return (
    <div className={classes.root}>
      <div className="container-fluid">
        <div className={classes.header}>
          <div>
            <div>
              <h1 className={classes.title}>
                <Icon icon={faCalendar} />
                <span>Informações adicionais</span>
              </h1>
            </div>
            <div>
              <Stepper current={1} count={3} />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div>
            <div>
              {session && (
                <div className={classes.info}>
                  <h3>
                    {agendamento.data &&
                      format(new Date(`${agendamento.data} ${agendamento.hour}`), 'EEEE, dd/LL HH:mm', {
                        locale: ptBR
                      })}
                  </h3>
                  <p>
                    {address.get('street')}
                    {address.has('number') && `, ${address.get('number')}`}
                  </p>
                  {session.vertical_data?.unit && <p>Unidade: {session.vertical_data.unit}</p>}
                  {session.vertical_data?.complement && <p>Complemento: {session.vertical_data.complement}</p>}
                  <p>
                    {address.get('neighborhood')} - {address.get('city')}, {address.get('state')}
                  </p>
                </div>
              )}
            </div>
            <div>
              <form onSubmit={handleNext}>
                <h4>Dados da pessoa que estará no imóvel ou que será responsável por receber o fotógrafo</h4>
                {profile && (
                  <FormCheck className={classes.check} onChange={handleResponsible}>
                    Eu estarei no imóvel ou receberei o fotógrafo
                  </FormCheck>
                )}
                <FormGroup label="Nome">
                  <FormText
                    onChange={input => handleOwnerInputAgendamento('name', input)}
                    value={agendamento?.owner?.name}
                    required
                  />
                </FormGroup>
                <FormGroup label="Telefone">
                  <FormMasked
                    mask="phone"
                    placeholder="(00) 0000-0000"
                    onChange={input => handleOwnerInputAgendamento('phone', input)}
                    value={agendamento?.owner?.phone}
                    required
                  />
                </FormGroup>
                <FormGroup label="E-mail">
                  <FormText
                    type="email"
                    onChange={input => handleOwnerInputAgendamento('email', input)}
                    value={agendamento?.owner?.email}
                    required
                  />
                </FormGroup>
                <FormGroup label="Chaves">
                  <FormText
                    placeholder="Informe ao fotógrafo onde estarão as chaves."
                    onChange={input => handleInputAgendamento('keys', input)}
                    required
                  />
                </FormGroup>
                <FormGroup label="Observações">
                  <FormTextArea onChange={input => handleInputAgendamento('extrainfo', input)} />
                </FormGroup>

                <div className={classes.row}>
                  <Button type="button" outline onClick={handleBack}>
                    VOLTAR
                  </Button>
                  <Button type="submit">AVANÇAR →</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
