import React from 'react'

import { Button } from 'shared/ui/button/Button'
import { RatingModal } from 'shared/ui/rating/RatingModal'

import { useModal } from 'shared/modal'
import { classNames } from 'shared/util/class-names'

import classes from '../SessionView.module.scss'
import { SessionStatus } from 'shared/enum/session/SessionStatus'

interface RatingProps {
  session: Resource.SessionResource
}

export const Ratings: React.FC<RatingProps> = ({ session }) => {
  const rating = useModal(RatingModal, { session })

  if (!session) {
    return <></>
  }
  // don't show if it's not delivered yet
  if (session.status !== SessionStatus.DELIVERED) return <></>

  return (
    <div className={classNames(classes.card, classes.center, classes.black)}>
      <p className={classNames(classes.text, classes.big)}>Avalie esse ensaio</p>
      <Button onClick={() => rating.open()} className="outline">
        AVALIAR →
      </Button>
    </div>
  )
}
