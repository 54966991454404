import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Welcome from './components/Welcome'
import Password from './components/Password'
import Company from './components/Company'
import Users from './components/Users'

import { useProfileContext } from 'hooks/ProfileContext'

const Router = () => {
  const [profile] = useProfileContext()

  if (profile === undefined) {
    return <Redirect to="/login" />
  }

  return (
    <Switch>
      <Route path="/onboard/welcome" component={Welcome} />
      <Route path="/onboard/password" component={Password} />
      <Route path="/onboard/company" component={Company} />
      <Route path="/onboard/users" component={Users} />

      <Redirect to="/onboard/welcome" />
    </Switch>
  )
}

export default Router
