import React from 'react'

import { Button } from 'shared/ui/button/Button'

import { classNames } from 'shared/util/class-names'

import classes from '../SessionView.module.scss'
import { parseAddressComponents } from 'shared/util/google-maps'
import { parseStrings } from 'shared/util/strings'

interface AddressProps {
  session: Resource.SessionResource
}

export const Address: React.FC<AddressProps> = ({ session }) => {
  if (!session) {
    return null
  }

  const address = parseAddressComponents(session.address?.address_components)
  const addressLabel = parseStrings(
    address?.get('administrative_area_level_2'),
    address?.get('administrative_area_level_1')
  )
  const addressLabel2 = parseStrings(address?.get('route'), address?.get('street_number'))

  // don't show if it's not delivered yet
  // if (session.status !== SessionStatus.CANCELED) return <></>

  return (
    <div className={classNames(classes.card, classes.center)}>
      <h2>Endereço</h2>
      <p>
        {addressLabel2} - {addressLabel}
      </p>
      <div className={classNames(classes.card, classes.map)}>
        <Button variant="flat" href={session.address?.url}>
          VER NO MAPA
        </Button>
      </div>
    </div>
  )
}
