import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './Badge.module.scss'

interface BadgeProps {
  className?: string
  color?: string
  variant?: 'danger' | 'primary' | 'success'
}

export const Badge: React.FC<BadgeProps> = ({ className, children, color, variant }) => {
  const klass = classNames(classes.badge, className)
  const style = {
    backgroundColor: color
  }
  return (
    <span className={klass} style={style}>
      {children}
    </span>
  )
}
