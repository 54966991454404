import React, { useState } from 'react'
import { format } from 'date-fns'

import { FormUpload } from 'shared/form/FormUpload'
import { Button } from '../../ui/button/Button'
import { Card } from '../../ui/card/Card'
import { Icon } from '../../ui/icon'
import { classNames } from '../../util/class-names'
import { toFormData } from '../../util/form-data'

import classes from './SessionDeliverableEditPhotos.module.scss'

interface SessionDeliverableEditPhotosProps {
  product: Resource.SessionResourceProduct<Resource.SessionDeliverablePhoto>
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

export const SessionDeliverableEditPhotos: React.FC<SessionDeliverableEditPhotosProps> = ({
  category,
  product,
  session
}) => {
  const [products, setProducts] = useState(product.deliverables)
  const [dragging, setDragging] = useState(-1)
  const [dragged, setDragged] = useState(-1)
  const handleChange = (tag_id: number) => (files: File[]) => {
    const denied = files.filter(file => {
      if (file.size > 3 * 1024 * 1024) {
        return true
      } else {
        const body = toFormData({ tag_id, file })
        fetch(`/api/sessions/${session.id}/${product.id}/photos`, { body, method: 'POST' })
          .then(response => {
            if (response.ok) {
              return response.json()
            } else {
              alert(`Falha ao realizar upload da foto ${file.name}!`)
              throw new Error('Upload failed')
            }
          })
          .then(({ data }) => setProducts(products => [...products, data]))
        return false
      }
    })
    if (denied.length) {
      const message = `As fotos ultrapassam o tamanho máximo permitido:\n${denied.map(file => `${file.name}\n`)}`
      alert(message)
    }
  }
  const handleDelete = (photo: Resource.SessionDeliverablePhoto) => (event: React.MouseEvent) => {
    event.preventDefault()
    const url = `/api/sessions/${session.id}/${product.id}/photos/${photo.id}`
    fetch(url, { method: 'DELETE' }).then(response => {
      if (response.ok) {
        setProducts(products => products.filter(({ id }) => id !== photo.id))
      } else {
        alert('Falha ao excluir foto!')
      }
    })
  }

  const handleDownload = (fileName: string, tag_id: number | undefined = undefined) => (event: React.MouseEvent) => {
    event.preventDefault()
    let url = `/api/sessions/${session.id}/${product.id}/photos/download`
    let body = undefined

    if (tag_id) {
      body = toFormData({ tag_id })
    }

    fetch(url, { method: 'POST', body })
      .then(response => {
        if (response.ok) {
          return response.blob()
        } else {
          throw new Error(`Download failed`)
        }
      })
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.zip'
        a.click()
      })
  }

  const handleFeatured = (photo: Resource.SessionDeliverablePhoto) => (event: React.MouseEvent) => {
    event.preventDefault()
    const url = `/api/sessions/${session.id}/${product.id}/photos/${photo.id}`
    const body = toFormData({ is_featured: !photo.is_featured })

    fetch(url, { method: 'POST', body }).then(response => {
      if (response.ok) {
        if (photo.is_featured) {
          setProducts(products => products.map(product => ({ ...product, is_featured: false })))
        } else {
          setProducts(products => products.map(product => ({ ...product, is_featured: product.id === photo.id })))
        }
      } else {
        alert('Falha ao destacar a foto!')
      }
    })
  }

  const photoProps = (photo: Resource.SessionResourceDeliverablePhoto, i: number) => {
    return {
      className: classNames(
        classes.photo,
        dragging === photo.id && classes.dragging,
        dragging > 0 && dragged === photo.id && classes.dragged
      ),
      draggable: true,
      onDragEnd: () => setDragging(-1),
      onDragStart: () => setDragging(photo.id),
      onDragEnter: () => setDragged(photo.id),
      onDragOver: (event: React.DragEvent) => {
        event.preventDefault()
      },
      onDrop: (event: React.DragEvent) => {
        event.preventDefault()
        const photo = products.find(({ id }) => dragging === id)
        if (photo && dragged !== dragging) {
          const order = products.findIndex(({ id }) => dragged === id)
          const request = {
            body: JSON.stringify({ order }),
            headers: [['Content-Type', 'application/json']],
            method: 'POST'
          }
          fetch(`/api/sessions/${session.id}/${product.id}/photos/${photo.id}`, request)
            .then(() =>
              setProducts(products => {
                const photos = [...products]
                const draggingPhoto = photos.find(({ id }) => dragging === id)!
                const draggedPhoto = photos.find(({ id }) => dragged === id)!
                const draggingIndex = photos.findIndex(({ id }) => dragging === id)
                const draggedIndex = photos.findIndex(({ id }) => dragged === id)
                photos.splice(draggedIndex, 1, draggingPhoto)
                photos.splice(draggingIndex, 1, draggedPhoto)
                return photos
              })
            )
            .catch(() => alert('Falha ao reordenar fotos!'))
        }
      }
    }
  }

  const handleFileName = (name: string | undefined) => {
    if (!name) {
      name = 'Fotos'
    }

    return name + format(Date.now(), '_dd_MM_yyyy_HH_mm')
  }

  return (
    <div className={classes.content}>
      {product.item.orientation && category !== 'client' && (
        <Card className={classes.orientation} variant="black">
          <Icon.Bell className={classes.icon} size="2x" />
          <h3 className={classes.title}>Instruções da empresa</h3>
          <p className={classes.text}>{product.item.orientation}</p>
        </Card>
      )}
      <div>
        <div className={classes.deliverables}>
          {product.item.tags.map(tag => (
            <div key={tag.id}>
              {category !== 'client' && (
                <FormUpload accept=".jpg,.jpeg,.png" multiple onChange={handleChange(tag.id!)}>
                  <span>Fazer upload das fotos (formatos JPG ou PNG) de até 3 MB</span>
                </FormUpload>
              )}
              <p className={classes.title}>
                <Button onClick={handleDownload(handleFileName(tag.name), tag.id)}>
                  <Icon.Download />
                </Button>
                {tag.name}
              </p>
              <div className={classes.photos}>
                {products.map(
                  (photo, i) =>
                    photo.tag_id === tag.id && (
                      <div key={photo.id} {...photoProps(photo, i)}>
                        <img src={photo.url} alt={'tag'} />
                        <button
                          className={classNames(classes.featured, photo.is_featured ? classes.active : '')}
                          onClick={handleFeatured(photo)}>
                          <Icon.Heart />
                        </button>
                        {category !== 'client' && (
                          <button className={classes.remove} onClick={handleDelete(photo)}>
                            <Icon.Times size="lg" />
                          </button>
                        )}
                      </div>
                    )
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={classes.rowbutton}>
          <Button onClick={handleDownload(handleFileName(product.item.item?.title))}>Baixar todas as fotos</Button>
        </div>
      </div>
    </div>
  )
}
