import styled from 'styled-components'

export const Wrapper = styled.div`
  display: block;
  max-width: 92%;
  width: 100%;
  margin: 0 auto;
  transition: all 300ms ease-in-out;

  @media only screen and (min-width: 720px) {
    width: 94%;
    margin: 0 auto;
  }

  @media only screen and (min-width: 960px) {
    width: 96%;
  }

  @media only screen and (min-width: 1124px) {
    width: 98%;
    display: block;
    max-width: 1175px;
  }

  &.users {
    padding: 20px 25px;
    margin: 0 0 40px;
    box-sizing: border-box;
    background: #f5f5f5;
    border-radius: 6px;
  }

  &.welcome-card {
    max-width: 100%;
    margin: 0 0 40px 0;
    padding: 15px 15px;
    border-radius: 6px;
    box-sizing: border-box;
    background: #f2f2f2;

    @media only screen and (min-width: 540px) {
      padding: 20px 20px;
    }

    @media only screen and (min-width: 720px) {
      padding: 20px 25px;
    }

    @media only screen and (min-width: 960px) {
      padding: 25px 30px;
    }

    @media only screen and (min-width: 1124px) {
      padding: 30px 35px;
    }
  }
`
