import React, { FC, Fragment } from 'react'
import { classNames } from 'shared/util/class-names'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import classes from './Stepper.module.scss'

interface StepperProps {
  current: number
  count: number
}

export const Stepper: FC<StepperProps> = ({ current, count }) => {
  const items = Array.from({ length: count }, (_, index) => ({
    completed: current > index,
    step: index + 1
  }))
  return (
    <div className={classes.root}>
      {items.map(({ completed, step }, i) => (
        <Fragment key={step}>
          <span className={classNames(completed && classes.completed, current === i && classes.current)}>
            {completed && <Icon icon={faCheck} />}
          </span>
        </Fragment>
      ))}
    </div>
  )
}
