import React from 'react'
import { SessionStatus } from 'shared/enum/session/SessionStatus'
import { formatDate } from 'shared/util/date'

interface ScheduledProps {
  scheduled_for: Date
  provider: any
  status: SessionStatus
}

function addDays(date: Date, days: number): Date {
  const copy = new Date(Number(date))
  copy.setDate(date.getDate() + days)
  return copy
}

export const SessionScheduled: React.FC<ScheduledProps> = ({ scheduled_for, provider, status }) => {
  let date = new Date(scheduled_for)

  // without a date, there is no scheduled event
  if (!scheduled_for) {
    return <></>
  }

  if (status === SessionStatus.CANCELED) {
    return <>Entrega cancelada</>
  }

  if (status === SessionStatus.NOT_SCHEDULED) {
    return <>Esperando agendamento</>
  }

  // if is scheduled at the past, show when it occour
  if (new Date(scheduled_for) < new Date()) {
    return (
      <>
        Aconteceu em {formatDate(date, 'dd/MM/yyyy')}
        <br />
        {provider && <>Fotógrafo(a): {provider?.name}</>}
      </>
    )
  }
  return (
    <>
      Prevista para {formatDate(addDays(date, 4), 'dd/MM/yyyy')}
      <br />
      {provider && <>Fotógrafo(a): {provider?.name}</>}
    </>
  )
}
