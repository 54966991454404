import React, { useCallback, useContext, useEffect, useState } from 'react'

function useProfile() {
  const [profile, setProfile] = useState<Model.ClientUser | null>()
  const setProfileCallback = useCallback(setProfile, [])
  useEffect(() => {
    fetch(`/api/auth/user`)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error('Response not OK')
      })
      .then(({ data }) => {
        if (data !== null && data?.scope !== 'client') {
          return fetch(`/api/auth/logout`).then(() => {
            setProfile(null)
          })
        } else {
          setProfile(data)
        }
      })
      .catch(() => setProfile(null))
  }, [])

  return [profile, setProfileCallback] as [
    Nullable<Model.ClientUser>,
    React.Dispatch<React.SetStateAction<Nullable<Model.ClientUser>>>
  ]
}

const ProfileContext = React.createContext<State<Model.ClientUser | null> | undefined>(undefined)

export function useProfileContext() {
  return useContext(ProfileContext)!
}

export const ProfileContextProvider: React.FC = props => {
  const [profile, setProfile] = useProfile()

  if (profile === undefined) {
    return null
  } else {
    return <ProfileContext.Provider value={[profile, setProfile]} {...props} />
  }
}
