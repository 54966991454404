import React, { useState } from 'react'

import { Button } from 'shared/ui/button/Button'

import { useClientServices } from 'shared/hooks/data/services'
import { useProfileContext } from 'hooks/ProfileContext'

import { Stepper } from 'components/Stepper'
import { ReactSVG } from 'react-svg'
import { classNames } from 'shared/util/class-names'

import classes from './BasicInfo.module.scss'

interface BasicInfoValue {
  address: google.maps.places.PlaceResult
  client: Model.Client
  costCenter: Model.CostCenter
  service: Model.Service
}

export const Service = ({ history, state: [sessions, setSessions] }: any) => {
  const [profile] = useProfileContext()
  const [value, setValue] = useState<Partial<BasicInfoValue>>({})

  const services = useClientServices(profile?.client.id)

  const handleService = (serviceId: string) => {
    const service = services?.find(({ id }) => id === Number(serviceId))
    setValue({ ...value, service })
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setSessions([
      ...sessions.map((session: any) => ({
        ...session,
        ...value
      }))
    ])
    history.push('/sessions/add/info')
  }

  return (
    <div>
      <Stepper count={4} current={0} />
      <form onSubmit={handleSubmit}>
        <h1>Escolha o serviço</h1>
        {services?.map((service: any, index: number) => {
          return (
            <div
              className={classNames(classes.service, value?.service?.id === service?.id ? classes.active : '')}
              key={index}
              onClick={() => handleService(service?.id)}>
              <h2>{service?.name}</h2>
              <div className={classes.listProducts}>
                {service?.products?.map(({ item }: any, index: number) => (
                  <div className={classes.product} key={index}>
                    <ReactSVG className={classes.productIcon} src={item.icon} />
                    <p className={classes.productTitle}>{item.title}</p>
                  </div>
                ))}
              </div>
            </div>
          )
        })}
        <Button size="lg" disabled={value.service === undefined}>
          AVANÇAR →
        </Button>
      </form>
    </div>
  )
}
