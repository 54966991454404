import { useEffect, useState } from 'react'
import { State } from 'shared/typings/state'

type Paging = {
  length: number
  page: number
}

export function useSessions(params?: any, paging?: Paging, setMeta?: State.Dispatch<Http.ResponseMeta>) {
  const [sessions, setSessions] = useState<Model.Session[]>()
  useEffect(() => {
    setSessions(undefined)
    const request = {
      body: JSON.stringify(params || {}),
      headers: [['Content-Type', 'application/json']],
      method: 'POST'
    }
    const page = paging?.page || 1
    const length = paging?.length || 25
    fetch(`/api/sessions/search?page=${page}&length=${length}`, request)
      .then(response => response.json())
      .then(({ data, meta }) => {
        setSessions(data)
        setMeta?.(meta)
      })
  }, [paging, params, setMeta])
  return sessions
}

export function useSession(key: Model.Key) {
  const [session, setSession] = useState<Resource.SessionResource>()
  useEffect(() => {
    fetch(`/api/sessions/${key.id}`)
      .then(response => response.json())
      .then(({ data }) => setSession(data))
  }, [key])
  return session
}
