import React from 'react'

import { Label } from 'components/Label'

import { useFilterContext } from '../../hooks/filters'
import { FormDateRange } from 'shared/form/FormDateRange'

export default () => {
  const [, filter] = useFilterContext()

  const onChange = ([from, to]: [string, string]) => {
    if (from && to) {
      filter('session_date', {
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString()
      })
    } else {
      filter('session_date', {})
    }
  }

  return (
    <Label>
      <p>Ensaio agendado para</p>
      <FormDateRange onChange={onChange} />
    </Label>
  )
}
