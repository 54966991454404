import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Btn from '../Button'
import Carousel from './Carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const Wrapper = styled.div`
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px 0;
  transition: all 300ms ease-in-out;

  @media only screen and (min-width: 540px) {
    padding: 60px 0;
  }

  @media only screen and (min-width: 720px) {
    width: 98%;
  }

  @media only screen and (min-width: 960px) {
    width: 96%;
  }

  @media only screen and (min-width: 1124px) {
    width: 94%;
    display: block;
    max-width: 1175px;
  }
`

const Title = styled.h3`
  text-align: center;
  font-size: 22px;
  margin-bottom: 40px;

  @media only screen and (min-width: 540px) {
    margin-bottom: 60px;
    font-size: 24px;
  }

  @media only screen and (min-width: 720px) {
    font-size: 26px;
  }

  @media only screen and (min-width: 960px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 1124px) {
    font-size: 32px;
  }
`

const Gallery = () => (
  <>
    <Wrapper>
      <Title>Na Birdie você tem</Title>

      <Carousel />

      <Link to="/onboard/welcome">
        <Btn className="center" style={{ textTransform: 'uppercase' }}>
          tenho interesse!
        </Btn>
      </Link>
    </Wrapper>
  </>
)

export default Gallery
