import React, { FC, MouseEvent, useEffect, useState } from 'react'
import {
  addDays,
  addMonths,
  isSameDay,
  isPast,
  isSunday,
  parseISO,
  startOfMonth,
  startOfWeek,
  subMonths
} from 'date-fns'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { useAgendamento } from '../../components/context/AgendamentoContext'

import classes from './Calendar.module.scss'
import { classNames } from 'shared/util/class-names'

interface CalendarProps {
  options?: any
  onChange: (_: Date) => void
}

type DateInfo = {
  date: Date
  disabled: boolean
}

type DateListItem = DateInfo | null

const weekDays = Array.from({ length: 7 }, (_, index) => {
  const date = addDays(startOfWeek(new Date()), index)
  const format = date.toLocaleString('pt-BR', {
    weekday: 'short'
  })
  return `${format.substr(0, 1).toLocaleUpperCase()}${format.substr(1, 2)}`
})

function getDayClass(item: DateListItem, selected: DateListItem | undefined, options: any): string {
  if (item) {
    const enabled =
      'dates' in options &&
      options.dates.find((day: string) => {
        const date = parseISO(`${day} 00:00:00`)
        return isSameDay(date, item.date)
      })
    return classNames(
      classes.day,
      enabled && classes.available,
      isPast(item.date) && classes.past,
      isSunday(item.date) && classes.sunday,
      item === selected && classes.selected
    )
  } else {
    return classNames(classes.day, classes.other)
  }
}

export const Calendar: FC<CalendarProps> = ({ options, onChange }) => {
  const agendamento = useAgendamento()
  const [date, setDate] = useState(startOfMonth(new Date()))
  const [dates, setDates] = useState<DateListItem[]>()
  const [selected, setSelected] = useState<DateListItem>()
  const handleClick = (item: DateListItem) => (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (item) {
      setSelected(item)
      onChange(item.date)
    }
  }
  const handleNext = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDate(date => addMonths(date, 1))
  }
  const handlePrevious = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDate(date => subMonths(date, 1))
  }
  useEffect(() => {
    const start = startOfWeek(date)
    const dates = Array.from({ length: 35 }, (_, index) => {
      const current = addDays(start, index)
      if (current.getMonth() === date.getMonth()) {
        return {
          date: current,
          disabled: isPast(current)
        }
      } else {
        return null
      }
    })
    setDates(dates)
  }, [date])
  useEffect(() => {
    if (agendamento.data && dates) {
      const day = new Date(`${agendamento.data} 00:00:00`)
      dates.forEach(item => {
        if (item) {
          if (isSameDay(item.date, day)) {
            setSelected(item)
            onChange(item.date)
          }
        }
      })
    }
  }, [agendamento, dates, onChange])
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <button className={classes.navigate} onClick={handlePrevious}>
          <Icon icon={faChevronLeft} />
        </button>
        <span className={classes.monthName}>{date.toLocaleString('pt-BR', { month: 'long' })}</span>
        <span className={classes.year}>{date.toLocaleString('pt-BR', { year: 'numeric' })}</span>
        <button className={classes.navigate} onClick={handleNext}>
          <Icon icon={faChevronRight} />
        </button>
      </div>
      <div className={classes.container}>
        <div className={classes.calendar}>
          <div className={classes.month}>
            {weekDays.map(weekDay => (
              <div className={classes.day} key={weekDay}>
                {weekDay}
              </div>
            ))}
            {dates?.map((item, i) => (
              <div className={getDayClass(item, selected, options)} key={i} onClick={handleClick(item)}>
                <div className={classes.content}>
                  {item &&
                    item.date.getDate().toLocaleString('pt-BR', {
                      minimumIntegerDigits: 2
                    })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
