import React, { createElement } from 'react'

import { SessionProductIcon } from 'shared/components/sessions/SessionProductIcon'
import { Item } from 'shared/enum/item'
import { Card } from 'shared/ui/card/Card'
import { CardHeader } from 'shared/ui/card/CardHeader'

import { SessionDeliverableBlueprints } from './SessionDeliverableBlueprints'
import { SessionDeliverableEditPhotos } from './SessionDeliverableEditPhotos'
import { SessionDeliverableTour } from './SessionDeliverableTour'
import { SessionDeliverableUrl } from './SessionDeliverableUrl'
import { SessionDeliverableVideos } from './SessionDeliverableVideos'

import classes from './Deliverables.module.scss'

function getComponent(
  item: number,
  category: 'admin' | 'client' | 'provider',
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableItem>,
  session: Resource.SessionResource
) {
  const props = { category, session }
  switch (item) {
    case Item.BLUEPRINTS:
      return createElement(SessionDeliverableBlueprints, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableBlueprint>,
        ...props
      })
    case Item.CAPTURE_PHOTOS:
    case Item.CAPTURE_TOUR:
      return createElement(SessionDeliverableUrl, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableUrl>,
        ...props
      })
    case Item.EDIT_PHOTOS:
    case Item.EDIT_TOUR:
      return createElement(SessionDeliverableEditPhotos, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverablePhoto>,
        ...props
      })
    case Item.PRESENTATION_TOUR:
      return createElement(SessionDeliverableTour, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableTour>,
        ...props
      })
    case Item.VIDEOS:
      return createElement(SessionDeliverableVideos, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableVideo>,
        ...props
      })
    default:
      throw new Error(`Component for item id ${item} not found`)
  }
}

interface SessionDeliverablesProps {
  className?: string
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

export const SessionDeliverables: React.FC<SessionDeliverablesProps> = ({ className, session, category = 'admin' }) => {
  return (
    <div className={className}>
      {session.products
        .filter(product => {
          if (category === 'client') {
            // TODO
            return [6, 7, 9, 10, 11].includes(product.item.id) // product.work_type === ItemWorkType.REMOTE
          }
          return product
        })
        .map(product => {
          return (
            <Card key={product.id} className={classes.card} shadow>
              <CardHeader className={classes.header}>
                <SessionProductIcon url={product.item.icon} />
                <h2 className={classes.title}>{product.item.label}</h2>
              </CardHeader>
              {getComponent(product.item.id, category, product, session)}
            </Card>
          )
        })}
    </div>
  )
}
