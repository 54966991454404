import React from 'react'
import { useHistory } from 'react-router-dom'

import { getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { TableColumn, TableRow } from 'shared/table'
import { Badge } from 'shared/ui/badge/Badge'
import { formatISO } from 'shared/util/date'
import { parseAddressComponents } from 'shared/util/google-maps'

import { useSessionContext } from '../../../hooks/sessions'
import { SessionScheduled } from './Scheduled'

const parseStrings = (...str: any[]) => {
  return str.reduce((prev, curr) => {
    if (curr === undefined) {
      return prev
    }

    if (prev === '') return curr

    return prev + ', ' + curr
  }, '')
}

export const Rows = () => {
  const [sessions] = useSessionContext()
  const history = useHistory()

  return (
    <>
      {sessions?.map((session: any, index: number) => {
        const address = parseAddressComponents(session?.address?.address_components)
        const addressLabel1 = parseStrings(address?.get('route'), address?.get('street_number'))
        const addressLabel2 = parseStrings(
          address?.get('administrative_area_level_2'),
          address?.get('administrative_area_level_1')
        )

        const onClick = () => {
          history.push(`/sessions/${session?.id}`)
        }

        return (
          <TableRow onClick={onClick} key={index}>
            <TableColumn size={0.7}>
              {session.id}
              <br />
              <Badge color={getSessionStatusColor(session.status)}>{getSessionStatusDescription(session.status)}</Badge>
            </TableColumn>
            <TableColumn align="flex-start">
              {session?.client_code && (
                <>
                  {session?.client_code} <br />
                </>
              )}
              {session?.service?.name}
            </TableColumn>
            <TableColumn align="flex-start" size={2}>
              {formatISO(session?.scheduled_for, 'dd/MM/yyyy HH:mm')}
              <br />
              {session?.provider?.name}
            </TableColumn>
            <TableColumn align="flex-start" size={3}>
              {addressLabel1 && (
                <>
                  {addressLabel1} <br />
                </>
              )}
              {addressLabel2 && addressLabel2}
            </TableColumn>
            <TableColumn align="flex-start">
              {session?.user?.name}
              <br />
              {formatISO(session?.created_at, 'dd/MM/yyyy')}
            </TableColumn>
            <TableColumn align="flex-start" size={2}>
              <SessionScheduled
                status={session?.status}
                provider={session?.provider}
                scheduled_for={session?.scheduled_for}
              />
            </TableColumn>
          </TableRow>
        )
      })}
    </>
  )
}
