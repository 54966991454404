import React, { useCallback, useContext, useState } from 'react'

function useFilters(): [any, any] {
  const [filter, setFilter] = useState<any>({})

  const handleFilterChange = useCallback((key: string, value: any) => {
    setFilter((_filter: any) => {
      switch (key) {
        case 'product_status':
          _filter[key] !== undefined && _filter[key][0] === value
            ? (_filter[key] = undefined)
            : (_filter[key] = [value])
          break

        default:
          _filter[key] = value
          break
      }
      return { ..._filter }
    })
  }, [])

  return [filter, handleFilterChange]
}

const FilterContext = React.createContext<[any, any] | undefined>(undefined)

export const useFilterContext = () => useContext(FilterContext)!

export const FilterProvider: React.FC = props => {
  const filter = useFilters()

  return <FilterContext.Provider value={filter} {...props} />
}
