import React, { useCallback, useContext, useEffect } from 'react'
import { useFilterContext } from './filters'
import { useSessionContext } from './sessions'
import { usePaginationContext } from './pagination'
import { useLoadingContext } from './loading'
import { Sort, useSortContext } from './sort'
import { useProfileContext } from 'hooks/ProfileContext'
import { useInfoContext } from './info'

const getSessions = (callback: any, page = 1, filter = {}, sort: Optional<Sort>) => {
  const body = JSON.stringify({ page, filter, ...sort })
  fetch(`/api/sessions/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
    .then(response => response.json())
    .then(callback)
}

function useMeta(): [any] {
  const [filter] = useFilterContext()
  const [, setSessions] = useSessionContext()
  const [{ page }, setPagination] = usePaginationContext()
  const [, setLoad] = useLoadingContext()
  const [sort] = useSortContext()
  const [profile] = useProfileContext()
  const [, setInfo] = useInfoContext()

  const reducer = useCallback(
    (action: string) => {
      switch (action) {
        case 'update':
          setLoad(true)

          getSessions(
            ({ data, last_page, total_sessions, total_pendings, total_delivereds, total_scheduleds }: any) => {
              setSessions(data?.sessions)
              setPagination('meta', {
                totalSessions: total_sessions,
                lastPage: last_page
              })
              setInfo({
                totalPendings: total_pendings,
                totalSessions: total_sessions,
                totalDelivereds: total_delivereds,
                totalScheduleds: total_scheduleds
              })

              setLoad(false)
            },
            page,
            {
              ...filter
            },
            sort
          )
          break
      }
    },
    [filter, page, setInfo, setLoad, setPagination, setSessions, sort]
  )

  const update = () => reducer('update')

  useEffect(update, [page, sort, profile])

  return [reducer]
}

const MetaContext = React.createContext<[any] | undefined>(undefined)

export const useMetaContext = () => useContext(MetaContext)!

export const MetaProvider: React.FC = props => {
  const meta = useMeta()

  return <MetaContext.Provider value={meta} {...props} />
}
