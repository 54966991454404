import React from 'react'

import { Button } from 'shared/ui/button/Button'
import { FormGroup } from 'shared/form/FormGroup'
import { FormText } from 'shared/form/FormText'

export const PhotographerOrientations = ({ history }: any) => {
  return (
    <form>
      <h1>Orientações ao Fornecedor</h1>
      <section>
        <FormGroup label="Nome de quem receberá">
          <FormText required />
        </FormGroup>
        <FormGroup label="Telefone">
          <FormText required />
        </FormGroup>
        <FormGroup label="E-mail">
          <FormText required />
        </FormGroup>
        <FormGroup label="Local das chaves">
          <FormText required />
        </FormGroup>
        <FormGroup label="Número de veículos">
          <FormText required />
        </FormGroup>
        <FormGroup label="Informações extras">
          <FormText required />
        </FormGroup>
      </section>
      <Button size="lg" outline onClick={history.goBack}>
        VOLTAR
      </Button>{' '}
      <Button size="lg">AVANÇAR →</Button>
    </form>
  )
}
