import React, { useState, useEffect } from 'react'
import { Overlay } from 'react-portal-overlay'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Steps from '../../../components/Steps'
import Btn from '../../../components/Button'

import ProfLegal from '../../../components/Users/ProfLegal'
import ProfFinan from '../../../components/Users/ProfFinan'
import ProfOperational from '../../../components/Users/ProfOperational'
import ProfUser from '../../../components/Users/ProfUser'

import SideImg from '../../../assets/images/side-image.png'
import AddIcon from '../../../assets/images/Icon-Add.svg'

import IconCheck from '../../../assets/images/check.svg'

import { useProfileContext } from '../../../hooks/ProfileContext'
import { addUsersFromClient } from '../../../services/users'

import { Wrapper } from '../../../components/Wrapper'
import { Main } from '../../../components/Main'
import { Before } from '../../../components/Before'
import { Content } from '../../../components/Content'
import { Call } from '../../../components/Call'
import { Text } from '../../../components/Text'

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;

  .outline {
    margin-right: 30px;
  }

  @media only screen and (max-width: 960px) {
    justify-content: space-between;

    .outline {
      margin-right: 0;
    }
  }
`

const Add = styled(Link)`
  display: flex;
  flex-direction: row;
  margin: -30px 0 40px;
`

const Img = styled.img`
  max-width: 35px;
  width: 100%;
  height: auto;
  margin-right: 5px;
`

const AddText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #0aa0d2;
`

const MyOverlay = styled.div`
  width: 60%;
  background: white;
  padding: 3rem;
  border-radius: 5px;
`

const MyImg = styled.img`
  margin: 10px auto;
  max-width: 55px;
  height: auto;
  display: block;
`

const Title = styled.h1`
  margin: 30px 0;
  text-align: center;
  padding: 0;
  color: #3bb54a;
`

const MyButton = styled.div`
  button {
    margin: 10px auto;
  }
`

const Users = () => {
  const [profile] = useProfileContext()
  let [users, setUsers] = useState<any[]>([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setUsers([
      {
        legal_representative: true
      },
      {
        finances_manager: true
      },
      {
        operation_manager: true
      }
    ])
  }, [])

  const addUser = () => users.push({})

  const saveForm: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void = e => {
    e.preventDefault()
    setOpen(true)
    addUsersFromClient(users, profile)
  }

  return (
    <>
      <Wrapper>
        <Main>
          <Before>
            <img src={SideImg} alt="sofa" />
          </Before>
          <Content>
            <Steps className={'users'} />

            <Call>Usuários</Call>
            <Text>Defina os usuários da sua empresa.</Text>

            <ProfLegal user={users[0]} />
            <ProfFinan user={users[1]} />
            <ProfOperational user={users[2]} />

            {users
              .filter((_, index) => index > 2)
              .map((user, key) => (
                <ProfUser
                  key={key}
                  user={user}
                  removeUser={() => {
                    let index = key + 3
                    users.splice(index, 1)
                    setUsers([...users])
                  }}
                />
              ))}

            <Add onClick={addUser} to="#">
              <Img src={AddIcon} alt="Adicionar Funcionario" />
              <AddText>Adicionar funcionário</AddText>
            </Add>

            <Buttons>
              <Link to="/">
                <Btn className={'outline'}>Mais Tarde</Btn>
              </Link>
              <Link to="/" onClick={saveForm}>
                <Btn>Finalizar</Btn>
              </Link>
            </Buttons>
          </Content>
        </Main>
      </Wrapper>
      <Overlay
        open={open}
        onClose={() => setOpen(false)}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          maxHeight: '100vh'
        }}>
        <MyOverlay>
          <MyImg src={IconCheck} alt="icon check" />
          <Title>Cadastro realizado com sucesso</Title>

          <MyButton>
            <a href="/sessions">
              <Btn>Prosseguir &#8594;</Btn>
            </a>
          </MyButton>
        </MyOverlay>
      </Overlay>
    </>
  )
}

export default Users
