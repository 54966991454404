import styled from 'styled-components'

export const Button = styled.button`
  display: block;
  background: #ffd600;
  border-radius: 100px;
  border: 3px solid transparent;
  color: #000000;
  min-width: 160px;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
  opacity: 1;
  box-sizing: border-box;
  transition: all 300ms ease-in-out;

  &:hover {
    background: black;
    color: white;
  }

  &.alert {
    background: yellow;
    color: black;
  }

  &.outline {
    background: transparent;
    border-color: #ffd600;

    &:hover {
      background: #ffd600;
      color: #000000;
    }
  }

  &.invalid {
    background: transparent;
    border-color: #d9d9d9;

    &:hover {
      background: #d9d9d9;
      color: #000000;
    }
  }

  @media only screen and (max-width: 960px) {
    margin: 0 auto 40px;
  }

  &.center {
    margin: 0 auto 40px;
  }
`

export default Button
