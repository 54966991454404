import React, { useState, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { format, isSameDay, parseISO } from 'date-fns'

import classes from './Calendar.module.scss'

import { Stepper } from 'components/Stepper'
import { Calendar } from './components/Calendar'
import { Schedule } from './components/Schedule'

import { useAgendamentoContext } from '../components/context/AgendamentoContext'

import { classNames } from 'shared/util/class-names'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { useSessionAvailability } from 'data/sessions'

type RouteParam<P extends string> = { [key in P]: string }

export const CalendarPage: React.FC = () => {
  const match = useRouteMatch<RouteParam<'id'>>()
  const availability = useSessionAvailability(match.params.id)

  const [availabilities, setAvailabilities] = useState<Date[]>()
  const [error, setError] = useState(false)
  const [agendamento, setAgendamento] = useAgendamentoContext()
  const handleDate = (date: Date) => {
    date = new Date(date)

    setAgendamento({
      data: format(date, 'yyyy-MM-dd'),
      hour: format(date, 'HH:mm')
    })
  }
  const handleCalendar = useCallback(
    (date: Date) => {
      if (availability) {
        const current = Object.keys(availability).find(day => {
          return isSameDay(parseISO(`${day} 00:00:00`), date)
        })
        if (current) {
          setAvailabilities((availability[current] as any[]) as Date[])
        }
      }
    },
    [availability]
  )
  const handleNext = (event: React.MouseEvent) => {
    if (agendamento.data && agendamento.hour) {
      window.scroll({ top: 0, behavior: 'smooth' })
    } else {
      event.preventDefault()
      setError(true)
    }
  }
  const dates = Object.keys(availability || {}).filter(key => {
    return availability && Array.isArray(availability[key]) && availability[key].length > 0
  })
  if (availability === false) {
    return (
      <div className={classes.root}>
        <div className={classes.unavailable}>
          <Icon.ExclamationCircle size="3x" />
          <h1>Ops, tivemos um problema :(</h1>
          <p>O ensaio solicitado não foi encontrado.</p>
        </div>
      </div>
    )
  } else if (availability) {
    return (
      <div className={classes.root}>
        <div className="container-fluid">
          <div className={classes.header}>
            <div className="row">
              <div className="col-sm-6">
                <h1 className={classes.title}>
                  <Icon.Calendar />
                  <span>Selecione um dia e horário</span>
                </h1>
              </div>
              <div className="col-sm-6">
                <Stepper current={0} count={3} />
              </div>
            </div>
          </div>
          <div className={classes.content}>
            <p className={classes.legend}>
              Trabalhamos com quatro horários disponíveis: 08h30, 10h30, 13h30 e 15h30. Se precisar de outro horário,
              fale com nosso suporte.
            </p>
            <div className={classes.container}>
              <div>
                <div className={classes.calendar}>
                  <Calendar options={{ dates }} onChange={handleCalendar} />
                </div>
              </div>
              <div>
                <div className={classes.schedule}>
                  <div className={classes.schedules}>
                    <Schedule availabilities={availabilities} onChange={handleDate} />
                  </div>
                  <div className={classNames(classes.feedback, error && classes.show)}>
                    <p className="text-danger text-center">Selecione um dia e horário para avançar</p>
                  </div>
                  <Button className={classes.submitButton} onClick={handleNext} to={`${match.url}/informacoes`}>
                    AVANÇAR →
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <InfoModal /> */}
      </div>
    )
  } else {
    return (
      <div className={classes.root}>
        <div className={classes.loading}>
          <Icon.CircleNotch spin size="2x" />
        </div>
      </div>
    )
  }
}
