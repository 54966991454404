import React from 'react'
import styled from 'styled-components'

import { useProfileContext } from '../../hooks/ProfileContext'

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 25px;
  margin: 0 0 40px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 6px;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Name = styled.p`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin: 0 0 5px;
`

const Company = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 3px;
`

const Email = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 3px;
`

const Number = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 3px;
`

const CallProfile = () => {
  const [profile] = useProfileContext()

  return (
    <Wrapper>
      <Details>
        <Name>{profile?.name}</Name>
        <Company>{profile?.client?.name}</Company>
        <Email>{profile?.email}</Email>
        <Number>{profile?.phone}</Number>
      </Details>
    </Wrapper>
  )
}

export default CallProfile
