import React, { useCallback, useContext, useState } from 'react'

function usePagination(): [any, any] {
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: 1,
    totalSessions: 0,
    sessionsPerPage: 25
  })

  const reducer = useCallback((action: string, page: number | any) => {
    switch (action) {
      case 'next':
        setPagination(pagination => {
          const nextPage = page ?? pagination.page
          return { ...pagination, page: Math.min(nextPage + 1, pagination.lastPage) }
        })
        break

      case 'select':
        setPagination(pagination => {
          const nextPage = page ?? pagination.page
          return { ...pagination, page: nextPage }
        })
        break

      case 'previous':
        setPagination(pagination => {
          const nextPage = page ?? pagination.page
          return { ...pagination, page: Math.max(nextPage - 1, 1) }
        })
        break

      case 'meta':
        setPagination(pagination => {
          const nextPage = page ?? pagination.page
          return { ...pagination, page: nextPage }
        })
        break
    }
  }, [])

  return [pagination, reducer]
}

const PaginationContext = React.createContext<[any, any] | undefined>(undefined)

export const usePaginationContext = () => useContext(PaginationContext)!

export const PaginationProvider: React.FC = props => {
  const pagination = usePagination()

  return <PaginationContext.Provider value={pagination} {...props} />
}
