import React, { useEffect, useState } from 'react'
import StarRatings from 'react-star-ratings'

import { FormGroup } from 'shared/form'
import { FormSelectTag } from 'shared/form/FormSelectTag'
import { FormTextArea } from 'shared/form/FormTextArea'
import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'
import { toFormData } from 'shared/util/form-data'

import classes from './RatingModal.module.scss'

interface ModalProps {
  close?: () => void
  session: Resource.SessionResource
  category?: 'client' | 'provider' | 'admin'
}

export const RatingModal: React.FC<ModalProps> = ({ session, close, category = 'client' }) => {
  const [rate, setRate] = useState<Partial<Model.Rate>>({})

  const save = () => {
    const init: RequestInit = {
      method: 'POST',
      body: toFormData(rate)
    }
    fetch(`/api/sessions/${session.id}/ratings`, init).then(close)
  }

  const dispath = (partial: Partial<Model.Rate>) => setRate({ ...rate, ...partial })

  useEffect(() => {
    if (session) {
      fetch(`/api/sessions/${session.id}/ratings`)
        .then(response => response.json())
        .then(({ data }: { data: Model.Rating }) => {
          if (data[category]) {
            setRate(data[category]!)
          }
        })
    }
  }, [session, category])

  return (
    <Container className={classes.container}>
      <Card>
        <h1>Avalie o ensaio</h1>
        <Card className={classes.card} solidBorder>
          <div className={classes.stars}>
            <StarRatings
              numberOfStars={5}
              rating={rate.rate}
              changeRating={(rate: number) => dispath({ rate })}
              starRatedColor="#0aa0d2"
              starEmptyColor="#C4C4C4"
              starDimension="40px"
              starSpacing="4px"
              name={`${category}-rate`}
            />
          </div>
          <FormGroup label="O que poderia melhorar?">
            <FormSelectTag category={`session/${category}`} value={rate.tags} onChange={tags => dispath({ tags })} />
          </FormGroup>
          <FormGroup label="Comentário">
            <FormTextArea value={rate.description} onChange={description => dispath({ description })} />
          </FormGroup>
        </Card>
        <div className={classes.row}>
          <Button outline onClick={close}>
            Cancelar
          </Button>
          <Button onClick={save}>Salvar</Button>
        </div>
      </Card>
    </Container>
  )
}
