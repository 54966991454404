import React, { FC, createContext, useContext, useState, useEffect } from 'react'

interface SessaoContextProviderProps {
  token: string
}

const SessaoContext = createContext<any | undefined>(undefined)

export function useSessaoContext() {
  return useContext(SessaoContext)
}

export const SessaoContextProvider: FC<SessaoContextProviderProps> = ({ children, token }) => {
  const [session, set] = useState<any>()

  useEffect(() => {
    fetch(`/api/sessions/public/${token}`)
      .then(response => response.json())
      .then(({ data }): any => set(data))
      .catch(() => set(null))
  }, [token])

  return <SessaoContext.Provider value={[session, set]} children={children} />
}
