import React, { useCallback, useContext, useState } from 'react'

function useLoading(): ReactState<boolean> {
  const [loading, setLoading] = useState(true)
  const setLoadingCallback = useCallback(setLoading, [])
  return [loading, setLoadingCallback]
}

const LoadingContext = React.createContext<ReactState<boolean> | undefined>(undefined)

export const useLoadingContext = () => useContext(LoadingContext)!

export const LoadingProvider: React.FC = props => {
  const loading = useLoading()

  return <LoadingContext.Provider value={loading} {...props} />
}
