import React from 'react'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { Button } from 'shared/ui/button/Button'

import classes from './SessionViewInfo.module.scss'
import { classNames } from 'shared/util/class-names'
import { SessionStatus } from 'shared/enum/session/SessionStatus'

interface ScheduleProps {
  session: Resource.SessionResource
}

export const Schedule: React.FC<ScheduleProps> = ({ session }) => {
  const scheduled_for = session.scheduled_for && parseISO(session.scheduled_for)

  // don't show this component without a session at its props
  if (!session || session.status === SessionStatus.CANCELED) {
    return null
  }

  return (
    <div
      className={classNames(
        classes.card,
        classes.center,
        session.status === SessionStatus.NOT_SCHEDULED ? classes.not_scheduled : ''
      )}>
      <h2>{session.status === SessionStatus.NOT_SCHEDULED ? 'Ensaio não agendado' : 'Data do agendamento'}</h2>
      {scheduled_for && (
        <h4 className={classes.schedule}>
          {format(scheduled_for, `iiii, dd/MM/yy 'às' HH':'mm`, {
            locale: ptBR
          })}
        </h4>
      )}
      {session.status === SessionStatus.NOT_SCHEDULED && (
        <div className={classes.actions}>
          <Button href={`/calendar/${session.token}`}>AGENDAR</Button>
        </div>
      )}
      {session.status === SessionStatus.SCHEDULED && (
        <div className={classes.actions}>
          <Button href={`/calendar/${session.token}`}>REAGENDAR</Button>
        </div>
      )}
    </div>
  )
}
