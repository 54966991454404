import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './Container.module.scss'

interface ContainerProps {
  className?: string
  size?: 'sm' | 'md'
}

export const Container: React.FC<ContainerProps> = ({ className, size, ...props }) => {
  const params = {
    className: classNames(className, classes.container, size && classes[`size-${size}`])
  }
  return <div {...params} {...props} />
}
