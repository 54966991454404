import React, { FC, createContext, useContext, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

const AgendamentoContext = createContext<any>(undefined)

export function useAgendamento() {
  return useContext(AgendamentoContext)![0]
}

export function useAgendamentoContext() {
  return useContext(AgendamentoContext)!
}

type RouteParam<P extends string> = { [key in P]: string }

const scheduleInit = {
  owner: {
    name: '',
    phone: '',
    email: ''
  },
  extrainfo: '',
  keys: ''
}

export const AgendamentoContextProvider: FC = ({ children }) => {
  const history = useHistory()
  const match = useRouteMatch<RouteParam<'id'>>()
  const state = useState<any>(scheduleInit)
  useEffect(() => {
    if (match.path === '/agendamento/:id' && match.isExact === false) {
      history.replace(`/agendamento/${match.params.id}`)
    }
    // eslint-disable-next-line
  }, [])
  return <AgendamentoContext.Provider value={state}>{children}</AgendamentoContext.Provider>
}
