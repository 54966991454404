import React, { FC } from 'react'

import classes from './Navbar.module.scss'

// TODO: remover
export const Navbar: FC = () => (
  <nav className={classes.nav}>
    <img className={classes.logo} src="/img/birdie-logo.svg" alt="Birdie Logo" />
  </nav>
)
