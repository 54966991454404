import React from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faCircle, faClipboard, faCopy, faFile, faFilePdf, faHeart } from '@fortawesome/free-regular-svg-icons'
import {
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faBars,
  faBell,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faCircleNotch,
  faDownload,
  faEnvelope,
  faExclamationCircle,
  faExternalLinkAlt,
  faLink,
  faList,
  faLock,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faPhoneAlt,
  faPlusCircle,
  faSignInAlt,
  faThumbsUp,
  faTimes,
  faTrashAlt,
  faUser,
  faCross
} from '@fortawesome/free-solid-svg-icons'

const icons = {
  AngleLeft: faAngleLeft,
  AngleRight: faAngleRight,
  ArrowDown: faArrowDown,
  ArrowRight: faArrowRight,
  ArrowUp: faArrowUp,
  Bars: faBars,
  Bell: faBell,
  Calendar: faCalendarAlt,
  Check: faCheck,
  CheckCircle: faCheckCircle,
  Circle: faCircle,
  CircleNotch: faCircleNotch,
  Clipboard: faClipboard,
  Copy: faCopy,
  Cross: faCross,
  Download: faDownload,
  Envelope: faEnvelope,
  ExclamationCircle: faExclamationCircle,
  ExternalLinkAlt: faExternalLinkAlt,
  Facebook: faFacebook,
  File: faFile,
  FilePdf: faFilePdf,
  Heart: faHeart,
  Instagram: faInstagram,
  Link: faLink,
  List: faList,
  Lock: faLock,
  MapMarkedAlt: faMapMarkedAlt,
  MapMarkerAlt: faMapMarkerAlt,
  Minus: faMinus,
  PhoneAlt: faPhoneAlt,
  PlusCircle: faPlusCircle,
  SignInAlt: faSignInAlt,
  Times: faTimes,
  ThumbsUp: faThumbsUp,
  TrashAlt: faTrashAlt,
  User: faUser,
  Whatsapp: faWhatsapp,
  Youtube: faYoutube
}

type Key = keyof typeof icons

export const Icon = Object.fromEntries(
  Object.entries(icons).map(([key, value]) => {
    const icon: React.FC<Omit<FontAwesomeIconProps, 'icon'>> = props => <FontAwesomeIcon icon={value} {...props} />
    return [key as Key, icon]
  })
) as Record<Key, React.FC<Omit<FontAwesomeIconProps, 'icon'>>>
