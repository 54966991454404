import React from 'react'
import { FormGroup, FormText } from 'shared/form'

interface VerticalHousingProps {
  readonly?: boolean
  value?: any
  onChange?: (newValue: any) => void
  vision?: string
}

export const VerticalHousings: React.FC<VerticalHousingProps> = ({
  readonly = false,
  value = {},
  onChange,
  vision = 'admin'
}) => {
  const dispatch = (partial: Partial<any>) => onChange && onChange({ ...value, ...partial })

  return (
    <>
      <FormGroup label="Local das chaves">
        <FormText readOnly={readonly} value={value.keys} onChange={keys => dispatch({ keys })} />
      </FormGroup>
      <FormGroup label="Area">
        <FormText readOnly={readonly || vision === 'client'} value={value.area} onChange={area => dispatch({ area })} />
      </FormGroup>
    </>
  )
}
