import styled from 'styled-components'

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 19px;

  &.hidden {
    display: none;
  }
`
