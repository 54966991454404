import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { FilterProvider } from './hooks/filters'
import { InfoProvider } from './hooks/info'
import { LoadingProvider } from './hooks/loading'
import { MetaProvider } from './hooks/meta'
import { PaginationProvider } from './hooks/pagination'
import { SessionsProvider } from './hooks/sessions'
import { SortProvider } from './hooks/sort'

import { SessionAdd } from './session-add/SessionAdd'
import { SessionList } from './session-list/SessionList'
import { SessionView } from './session-view/SessionView'

export const Sessions: React.FC = () => {
  return (
    <InfoProvider>
      <LoadingProvider>
        <SortProvider>
          <PaginationProvider>
            <FilterProvider>
              <SessionsProvider>
                <MetaProvider>
                  <Switch>
                    <Route exact path="/sessions" component={SessionList} />
                    <Route path="/sessions/add" component={SessionAdd} />
                    <Route path="/sessions/:id" component={SessionView} />
                    <Redirect to="/sessions" />
                  </Switch>
                </MetaProvider>
              </SessionsProvider>
            </FilterProvider>
          </PaginationProvider>
        </SortProvider>
      </LoadingProvider>
    </InfoProvider>
  )
}
