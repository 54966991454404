import { useProfileContext } from 'hooks/ProfileContext'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from 'shared/ui/button/Button'
import { useModal } from 'shared/modal'
import { Icon } from 'shared/ui/icon'
import { ErrorModal } from 'shared/ui/payment/ErrorModal'
import { toFormData } from 'shared/util/form-data'

import classes from './Login.module.scss'

export const Login = () => {
  const [, setProfile] = useProfileContext()
  const history = useHistory()
  const [state, setState] = useState({
    email: '',
    password: ''
  })
  const modal = useModal(ErrorModal)
  const handleChange = (key: keyof typeof state) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setState(state => ({ ...state, [key]: value }))
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const body = toFormData(state)
    fetch(`/api/auth/login`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          modal.open({
            title: 'Erro no login!',
            message: 'Por favor, confira seus dados e preencha novamente!'
          })
        }
      })
      .then(({ data }) => {
        if (data?.scope !== 'client') {
          modal.open({
            title: 'Você está tentando acessar a plataforma com outro tipo de conta.',
            message: 'Por favor, acesse a plataforma correta deste login!'
          })
          return fetch(`/api/auth/logout`, { method: 'POST' }).then(() => setProfile(null))
        }
        setProfile(data)
        // this occour only one time
        if (data.first_login === true) {
          history.push('/onboard')
        } else {
          history.push('/sessions')
        }
      })
      .catch(() =>
        modal.open({
          title: 'Erro no login!',
          message: 'Por favor, confira seus dados e preencha novamente!'
        })
      )
  }
  const email = {
    autoComplete: 'email',
    onChange: handleChange('email'),
    placeholder: 'E-mail',
    required: true,
    type: 'email',
    value: state.email
  }
  const password = {
    autoComplete: 'current-password',
    onChange: handleChange('password'),
    placeholder: 'Senha',
    required: true,
    type: 'password',
    value: state.password
  }
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.logo}>
          <img src="/img/logo-birdie.svg" alt="Logo Birdie" />
        </div>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.content}>
            <h1 className={classes.title}>Faça seu login</h1>
            <div className={classes.input}>
              <span className={classes.icon}>
                <Icon.Envelope />
              </span>
              <input {...email} />
            </div>
            <div className={classes.input}>
              <span className={classes.icon}>
                <Icon.Lock />
              </span>
              <input {...password} />
            </div>
            <div className={classes.action}>
              <Button className={classes.button}>ENTRAR</Button>
            </div>
            <div className={classes.forgotPassword}>
              Esqueceu sua <a href="/login/recover">senha?</a>
            </div>
            <div className={classes.createAcccount}>
              Não tem conta?{' '}
              <a target="blank" href="/pre-cadastro">
                Cadastre-se!
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
  /*
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.content}>
          <div className={classes.logo}>
            <img src="https://birdie.com.br/img/logo-birdie.svg" alt="computer icon" />
          </div>
          <div className={classes.login}>
            <fieldset>
              <legend>Login</legend>
              <Form onSubmit={onSubmit}>
                <FormText onChange={setEmail} type="email" placeholder="E-mail" required />
                <FormText onChange={setPassword} type="password" placeholder="Senha" required />
                <Button>LOGIN</Button>
              </Form>
            </fieldset>
            <div className={classes.forgotPassword}>
              Esqueceu sua <a href="/login/recover">senha?</a>
            </div>
            <div className={classes.createAcccount}>
              Não tem conta?{' '}
              <a target="blank" href="/pre-cadastro">
                Cadastre-se!
              </a>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )*/
}
