import React, { FC } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { Stepper } from 'components/Stepper'

import { useAgendamentoContext } from '../components/context/AgendamentoContext'
import { useSessaoContext } from '../components/context/SessaoContext'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

import classes from './Confirmation.module.scss'
import { parseAddress } from 'shared/util/google-maps'
import { toFormData } from 'shared/util/form-data'
import { Button } from 'shared/ui/button/Button'

type RouteParam<P extends string> = { [key in P]: string }

export const Confirmation: FC = () => {
  const history = useHistory()
  const [session] = useSessaoContext()
  const [agendamento] = useAgendamentoContext()
  const match = useRouteMatch<RouteParam<'id'>>()
  const address = parseAddress(session?.address?.address_components)

  const handleBack = () => {
    history.go(-1)
    window.scroll({ top: 0, behavior: 'smooth' })
  }
  const handleNext = () => {
    const { owner, ...scheduling } = agendamento
    const body = toFormData({
      ...scheduling,
      scheduling_responsible_info: {
        email: owner.email,
        name: owner.name,
        phone: owner.phone
      }
    })
    fetch(`/api/sessions/public/${match.params.id}`, { body, method: 'POST' })
      .then(() => {
        history.push('finalizacao')
        window.scroll({ top: 0, behavior: 'smooth' })
      })
      .catch(() => alert('Erro!'))
  }
  return (
    <div className={classes.root}>
      <div>
        <div className={classes.header}>
          <div>
            <div>
              <h1 className={classes.title}>
                <Icon icon={faCalendar} />
                <span>Confirme o ensaio</span>
              </h1>
            </div>
            <div>
              <Stepper current={2} count={3} />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div>
            <div>
              {session && (
                <div className={classes.info}>
                  <h3>
                    {agendamento.data &&
                      format(new Date(`${agendamento.data} ${agendamento.hour}`), 'EEEE, dd/LL HH:mm', {
                        locale: ptBR
                      })}
                  </h3>
                  <p>
                    {address.get('street')}
                    {address.has('number') && `, ${address.get('number')}`}
                  </p>
                  {session.vertical_data?.unit && <p>Unidade: {session.vertical_data.unit}</p>}
                  {session.vertical_data?.complement && <p>Complemento: {session.vertical_data.complement}</p>}
                  <p>
                    {address.get('neighborhood')} - {address.get('city')}, {address.get('state')}
                  </p>
                </div>
              )}
            </div>
            <div>
              <p>Quem receberá o fotógrafo?</p>
              <p>Nome: {agendamento.owner.name}</p>
              <p>E-mail: {agendamento.owner.email}</p>
              <p>Telephone: {agendamento.owner.phone}</p>
              <p>Chaves</p>
              <p>{agendamento.keys}</p>
              {agendamento.extrainfo && (
                <>
                  <p>Observações</p>
                  <p>{agendamento.extrainfo}</p>
                </>
              )}
              <div className={classes.row}>
                <Button outline onClick={handleBack}>
                  VOLTAR
                </Button>
                <Button onClick={handleNext}>AVANÇAR →</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
