import React, { useCallback, useContext, useState } from 'react'

function useInfo(): ReactState<any> {
  const [info, setInfo] = useState<any>({})
  const setInfoCallback = useCallback(setInfo, [])
  return [info, setInfoCallback]
}

const InfoContext = React.createContext<ReactState<any> | undefined>(undefined)

export const useInfoContext = () => useContext(InfoContext)!

export const InfoProvider: React.FC = props => {
  const info = useInfo()

  return <InfoContext.Provider value={info} {...props} />
}
