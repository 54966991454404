import React, { useState } from 'react'

import { FormGroup, FormText } from 'shared/form'
import { Button } from 'shared/ui/button/Button'

import { changePassword } from 'services/password'
import { useHistory } from 'react-router-dom'
import { useModal } from 'shared/modal'
import { ErrorModal } from 'shared/ui/payment/ErrorModal'

export default () => {
  const history = useHistory()

  const [password, setPassword] = useState<string>()
  const [match, setMatch] = useState<string>()

  const modal = useModal(ErrorModal)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (password && password === match) {
      changePassword(password)
        .then(() => {
          modal.open({ title: 'Senha alterada com sucesso!', message: '' })
          history.push('/sessions/add')
        })
        .catch(() => modal.open({ title: 'Aviso!', message: 'A senha informada é inválida!' }))
    } else {
      modal.open({ title: 'Aviso!', message: 'As senhas não conferem!' })
    }
  }

  return (
    <div>
      <h2>Cadastre sua senha</h2>
      <form onSubmit={onSubmit}>
        <FormGroup label="Senha">
          <FormText onChange={setPassword} type="password" placeholder="Nova senha" required />
        </FormGroup>
        <FormGroup label="Repetir a senha">
          <FormText type="password" placeholder="*****" required value={match} onChange={setMatch} />
        </FormGroup>
        <Button type="submit">Salvar</Button>
      </form>
    </div>
  )
}
