import React from 'react'

import classes from './SessionViewInfo.module.scss'
import { classNames } from 'shared/util/class-names'
import { SessionStatus } from 'shared/enum/session/SessionStatus'

interface ServiceProps {
  session: Resource.SessionResource
}

export const Service: React.FC<ServiceProps> = ({ session }) => {
  // don't show this component without a session at its props
  if (!session) {
    return null
  }

  // don't show if hit any of this values
  if (session.status === SessionStatus.CANCELED) return null
  if (session.status === SessionStatus.DELIVERED) return null
  if (session.status === SessionStatus.AT_REVISION) return null
  if (session.status === SessionStatus.NOSHOW_CLIENT) return null
  if (session.status === SessionStatus.NOSHOW_PROVIDER) return null
  if (session.status === SessionStatus.PENDING_TO_DELIVER) return null

  return (
    <div className={classNames(classes.card, classes.center)}>
      <h2>SERVIÇO DO CLIENTE</h2>
      <span>{session.service.name}</span>
    </div>
  )
}
