import { useEffect, useState } from 'react'

type SessionAvailability = {
  [key: string]: string[]
}

export function useSessionAvailability(key: string) {
  const [availability, setAvailability] = useState<SessionAvailability | false>()
  useEffect(() => {
    fetch(`/api/session-calendar/public/${key}`, { method: 'POST' })
      .then(response => response.json())
      .then(({ data }: any) => setAvailability(data as SessionAvailability))
      .catch(() => setAvailability(false))
  }, [key])
  return availability
}
