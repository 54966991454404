import React, { useState } from 'react'
import { classNames } from 'shared/util/class-names'
import { SessionStatus } from 'shared/enum/session/SessionStatus'

import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { FormGroup, FormText } from 'shared/form'

import classes from '../SessionView.module.scss'
import { toFormData } from 'shared/util/form-data'

interface RatingProps {
  session: Resource.SessionResource
}

enum Save {
  OK = 1,
  SAVING = 2,
  ERROR = 3
}

export const ResponsibleInformation: React.FC<RatingProps> = ({ session }) => {
  const [saved, setSaved] = useState<Save>(Save.OK)
  const [information, setInformation] = useState<Resource.ResponsibleInformation>(session.responsible_information ?? {})

  const dispatch = (partial: Partial<Resource.ResponsibleInformation>) => setInformation({ ...information, ...partial })

  if (!session) {
    return <></>
  }

  // don't show if it's not delivered yet
  if (session.status === SessionStatus.CANCELED) return <></>
  if (session.status === SessionStatus.NOSHOW_CLIENT) return <></>
  if (session.status === SessionStatus.NOSHOW_PROVIDER) return <></>

  let canUpdate = session.status !== SessionStatus.DELIVERED

  const save = () => {
    if (canUpdate) {
      setSaved(Save.SAVING)
      const init: RequestInit = {
        method: 'POST',
        body: toFormData({ responsible_information: information })
      }
      fetch(`/api/sessions/${session.id}`, init)
        .then(() => setTimeout(() => setSaved(Save.OK), 1000))
        .catch(() => {
          setSaved(Save.ERROR)
          alert('Não foi possível atualizar a sessão.')
        })
    }
  }

  return (
    <div className={classNames(classes.card)}>
      <h2 className={classes.title}>Dados do contato</h2>
      <FormGroup label="Nome">
        <FormText onChange={value => dispatch({ name: value })} value={information.name} readOnly={!canUpdate} />
      </FormGroup>
      <FormGroup label="E-mail">
        <FormText onChange={value => dispatch({ email: value })} value={information.email} readOnly={!canUpdate} />
      </FormGroup>
      <FormGroup label="Telefone">
        <FormText onChange={value => dispatch({ phone: value })} value={information.phone} readOnly={!canUpdate} />
      </FormGroup>

      {canUpdate && (
        <div className={classes.row}>
          <Button onClick={save}>Salvar alterações</Button>
          <div className={classes.check}>
            {canUpdate && saved === Save.OK && <Icon.Check />}
            {canUpdate && saved === Save.SAVING && <Icon.CircleNotch spin />}
            {canUpdate && saved === Save.ERROR && <Icon.Cross />}
          </div>
        </div>
      )}
    </div>
  )
}
