import React from 'react'
import { FormGroup, FormText } from 'shared/form'

interface VerticalCarProps {
  readonly?: boolean
  value?: any
  onChange?: (newValue: any) => void
}

export const VerticalCars: React.FC<VerticalCarProps> = ({ readonly = false, value = {}, onChange }) => {
  const dispatch = (partial: Partial<any>) => onChange && onChange({ ...value, ...partial })

  return (
    <>
      <FormGroup label="Local dos carros">
        <FormText readOnly={readonly} value={value.keys} onChange={keys => dispatch({ keys })} />
      </FormGroup>
    </>
  )
}
