import React from 'react'

import phone from '../../assets/phone.svg'
import calendar from '../../assets/calendar.svg'

import classes from './NextSteps.module.scss'

export default () => {
  return (
    <section className={classes.nextsteps}>
      <h2>Próximos passos</h2>
      <div className={classes.circle}>
        <img src={phone} width="60px" height="60px" alt="phone" />
        <span>Pagamento</span>
        <p>
          Você será direcionado à página de pagamento, optando pelo melhor plano para você. Você pode pagar com boleto
          ou cartão.
        </p>
      </div>
      <div className={classes.circle}>
        <img src={calendar} width="60px" height="60px" alt="calendar" />
        <span>Ensaio</span>
        <p>Após o pagamento,você será encaminhado ao nosso sistema e poderá realizar o seu primeiro ensaio :)</p>
      </div>
    </section>
  )
}
