import { toFormData } from 'shared/util/form-data'
import api from './api'

export const getClientServices = async () => {
  const { data }: Response<Service> = await fetch('/api/services').then(res => res.json())

  return data
}

export const updateClient = async (client: Partial<Client>, logo: File | undefined = undefined) => {
  const form = toFormData(client)

  if (logo) {
    form.set('logo', logo)
  }

  const request: RequestInit = {
    body: form,
    method: 'POST'
  }

  const { data } = await api<any>('/api/client', request, false)

  return data
}
