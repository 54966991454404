import { Stepper } from 'components/Stepper'
import { useProfileContext } from 'hooks/ProfileContext'
import React from 'react'

import { Button } from 'shared/ui/button/Button'
import { CardGrey } from 'shared/ui/card/CardGrey'
import { parseAddressComponents } from 'shared/util/google-maps'

export const Confirm = ({ history, state: [sessions, setSessions] }: any) => {
  const [profile] = useProfileContext()
  const { address, responsible_information, service } = sessions[0]
  const addr = parseAddressComponents(address?.address_components)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const requests = sessions.map((session: any) => {
      const data = {
        ...session,
        client: { id: profile?.client.id },
        service: { id: service.id }
      }

      const options = {
        body: JSON.stringify(data),
        headers: [['Content-Type', 'application/json']],
        method: 'POST'
      }

      return fetch(`/api/sessions`, options)
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Erro ocorreu.')
          }
        })
        .then(({ data }) => data.token)
    })
    Promise.all(requests)
      .then(tokens => {
        setSessions((sessions: any[]) => {
          return sessions.map((session, i) => Object.assign(session, { token: tokens[i] }))
        })
      })
      .then(() => history.push('/sessions/add/scheduling'))
      .catch(() => alert('Falha ao salvar sessões!'))
  }
  return (
    <div>
      <Stepper count={4} current={3} />
      <form onSubmit={handleSubmit}>
        <h1>Confirmar informações</h1>
        <section>
          <CardGrey>
            <h3>SERVIÇO</h3>
            <p>{profile?.client?.name}</p>
            <p>{service?.name}</p>
            <h3>LOCAL</h3>
            <p>
              {addr?.get('route')}
              {addr?.has('street_number') && `, ${addr?.get('street_number')}`}
            </p>
            <p>
              {addr?.get('sublocality')} - CEP: {addr?.get('postal_code')}
            </p>
            <p>
              {addr?.get('administrative_area_level_1')} - {addr?.get('administrative_area_level_1')}
            </p>
            {sessions?.map(({ vertical_data }: any, i: number) => (
              <div key={i}>
                <h3>Ensaio {i + 1}</h3>
                <p>
                  Ref: {vertical_data?.reference} - Unidade {vertical_data?.unit}/{vertical_data?.complement} -{' '}
                  {vertical_data?.area}m<sup>2</sup>
                </p>
              </div>
            ))}
            <h3>RESPONSÁVEL</h3>
            <p>Responsável: {responsible_information?.name}</p>
            <p>
              {responsible_information?.phone} - {responsible_information?.email}
            </p>
          </CardGrey>
        </section>
        <Button outline onClick={history.goBack}>
          VOLTAR
        </Button>{' '}
        <Button>AVANÇAR →</Button>
      </form>
    </div>
  )
}
