import React from 'react'
import styled from 'styled-components'

import Icone1 from '../../assets/images/icones/apresentacao/icone-01.svg'
import Icone2 from '../../assets/images/icones/apresentacao/icone-02.svg'
import Icone3 from '../../assets/images/icones/apresentacao/icone-03.svg'
import Icone4 from '../../assets/images/icones/apresentacao/icone-04.svg'
import Icone5 from '../../assets/images/icones/apresentacao/icone-05.svg'
import { Button } from 'shared/ui/button/Button'
import { useProfileContext } from 'hooks/ProfileContext'

const How = styled.div`
  background: #f5f5f5;
`

const Wrapper = styled.div`
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px 0;
  transition: all 300ms ease-in-out;

  @media only screen and (min-width: 540px) {
    padding: 60px 0;
  }

  @media only screen and (min-width: 720px) {
    width: 98%;
  }

  @media only screen and (min-width: 960px) {
    width: 96%;
  }

  @media only screen and (min-width: 1124px) {
    width: 94%;
    display: block;
    max-width: 1175px;
  }
`

const Title = styled.h3`
  text-align: center;
  font-size: 22px;
  margin-bottom: 40px;

  @media only screen and (min-width: 540px) {
    margin-bottom: 60px;
    font-size: 24px;
  }

  @media only screen and (min-width: 720px) {
    font-size: 26px;
  }

  @media only screen and (min-width: 960px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 1124px) {
    font-size: 32px;
  }
`

const CardList = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
  height: 300px;

  @media only screen and (min-width: 540px) {
    margin-bottom: 60px;
  }

  @media only screen and (min-width: 1124px) {
    font-size: 32px;
    overflow: hidden;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 25px 15px;
  min-width: 190px;
  width: 190px;
  height: 260px;
  border-radius: 17px;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin-right: 30px;
  margin-left: 30px;

  @media only screen and (min-width: 1124px) {
    margin-right: 0;
    margin-left: 0;
  }
`

const Img = styled.img``

const Text = styled.p`
  max-width: 176px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #000;
`

const HowItWorks = () => {
  const [profile] = useProfileContext()
  return (
    <How>
      <Wrapper>
        <Title>Como funciona</Title>

        <CardList>
          <Card>
            <Img src={Icone1} alt="Mini Mapa" />
            <Text>Você informa o endereço</Text>
          </Card>
          <Card>
            <Img src={Icone2} alt="Calendario" />
            <Text>Seu cliente escolhe uma data para o ensaio</Text>
          </Card>
          <Card>
            <Img src={Icone3} alt="Fotografia" />
            <Text>O fotógrafo comparece com o equipamento para fazer o ensaio</Text>
          </Card>
          <Card>
            <Img src={Icone4} alt="Website Birdie" />
            <Text>Nós preparamos o material para você aproveitar ao máximo</Text>
          </Card>
          <Card>
            <Img src={Icone5} alt="Email-Celular" />
            <Text>Você recebe o material no seu e-mail</Text>
          </Card>
        </CardList>

        <Button
          href={profile ? '/sessions/add' : '/login'}
          style={{ minWidth: '160px', minHeight: '48px', margin: '0 auto', display: 'table' }}>
          Solicitar o primeiro ensaio →
        </Button>
      </Wrapper>
    </How>
  )
}

export default HowItWorks
