import React, { useEffect, useState } from 'react'
import { toFormData } from 'shared/util/form-data'

import classes from './Cost.module.scss'

interface Label {
  title: string
  label: string
}

interface CostProps {
  name: string
  labels: {
    principals: Label[]
    secondaries: Label[]
    rules: string[][]
  }
}

export default () => {
  const [costs, setCosts] = useState<CostProps>()

  useEffect(() => {
    const init: RequestInit = {
      method: 'POST',
      body: toFormData({ filter: { type: 'min' } })
    }
    fetch('/api/prices/search', init)
      .then(req => req.json())
      .then(({ data }) => data)
      .then(prices => {
        if (typeof prices.rule === 'string') {
          prices.rule = JSON.parse(prices.rule)
        }

        setCosts(prices.rule)
      })
  }, [])

  if (!costs) {
    return null
  }

  return (
    <section className={classes.cost}>
      <h2>Quanto custa?</h2>
      <div className={classes.card}>
        <div className={classes.deliverables}>
          <div className={classes.tour}>
            <h3>TOUR VIRTUAL</h3>
            <small>A PARTIR DE</small>
            <div>
              <span>R$</span>
              <span>399,00</span>
            </div>
          </div>
          <div className={classes.others}>
            <div>
              <span>VÍDEO</span>
              <span>+ R$ 89,00</span>
            </div>
            <div>
              <span>PLANTA</span>
              <span>+ R$ 109,00</span>
            </div>
            <div>
              <span>FOTOS</span>
              <span>incluso</span>
            </div>
          </div>
        </div>
        <div className={classes.info}>
          <p>Áreas de até 150m²</p>
          <p>6 meses de hospedagem do tour</p>
          <p>São Paulo, Rio de Janeiro e Porto Alegre</p>
        </div>
      </div>
    </section>
  )
}
