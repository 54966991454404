import React, { useEffect, useState } from 'react'
import { classNames } from 'shared/util/class-names'

import classes from './FormSelectTag.module.scss'

interface FormTagProps {
  onChange?: (selected: Model.Tag[]) => void
  category?: string
  tags?: Model.Tag[]
  value?: Model.Tag[]
  readonly?: boolean
}

export const FormSelectTag: React.FC<FormTagProps> = ({
  onChange,
  tags = [],
  value = [],
  readonly = false,
  category
}) => {
  const [all, setAll] = useState<Model.Tag[]>(tags)

  // update all tags from parent
  useEffect(() => {
    if (category) {
      fetch(`/api/tag-category/${category}`)
        .then(res => res.json())
        .then(({ data }) => setAll(data.tags))
    }

    // if has category, should update one time only
  }, [category])

  const flipValue = (tag: Model.Tag) => {
    // if it's in value, remove it
    let index = value.findIndex(t => t.id === tag.id)

    if (index !== -1) {
      value.splice(index, 1)
    } else {
      // else, add it
      value.push(tag)
    }

    // ordering at the end
    value.sort((tagA, tabB) => tagA.id! - tabB.id!)

    // update only if onChange exists
    if (onChange) {
      // creates a new array
      // this is safer
      onChange([...value])
    }
  }

  return (
    <div className={classNames(classes.tagger, readonly ? classes.active : classes.desactive)}>
      {all.map((tag: Model.Tag, index: number) => {
        const isActive = value?.find(value => value.id === tag.id)

        const onClick = !readonly ? () => flipValue(tag) : undefined

        return (
          <div className={classNames(classes.taggable, isActive ? classes.active : '')} key={index} onClick={onClick}>
            {tag.name}
          </div>
        )
      })}
    </div>
  )
}
