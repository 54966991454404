import api from './api'

export const addUsers = async (users: User[]) => {
  const body = JSON.stringify({ users })

  let { data } = await api<User[]>(`/api/users`, { body })

  return data
}

export const addUsersFromClient = async (users: User[], profile?: Profile | null) => {
  users.forEach(user => {
    const body = JSON.stringify(user)
    api<any[]>(`/api/users`, { method: 'POST', body })
  })
}
