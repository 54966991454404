import React, { useState } from 'react'

import { ServiceProductSessionStatus } from 'shared/enum/service-product-session'
import { FormGroup } from 'shared/form/FormGroup'
import { FormInputGroup, FormInputGroupAppend } from 'shared/form/FormInputGroup'
import { FormText } from 'shared/form/FormText'
import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util/form-data'

import classes from './SessionDeliverableUrl.module.scss'

interface SessionDeliverableUrlProps {
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableUrl>
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

export const SessionDeliverableUrl: React.FC<SessionDeliverableUrlProps> = ({ product, session }) => {
  const [current, setCurrent] = useState(product.deliverables?.[0]?.url || '')
  const [delivered, setDelivered] = useState(product.status === ServiceProductSessionStatus.DELIVERED)
  const handleDeliver = (event: React.MouseEvent) => {
    event.preventDefault()
    const url = `/api/sessions/${session.id}/${product.id}`
    const body = toFormData({ status: ServiceProductSessionStatus.DELIVERED })
    fetch(url, { body, method: 'POST' }).then(response => {
      if (response.ok) {
        setDelivered(true)
      } else {
        alert('Falha ao entregar!')
      }
    })
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const url = `/api/sessions/${session.id}/${product.id}/urls`
    const body = toFormData({ url: current })
    fetch(url, { body, method: 'POST' }).then(response => {
      if (response.ok) {
        alert('Salvo com sucesso!')
      } else {
        alert('Falha ao salvar a URL!')
      }
    })
  }

  return (
    <div className={classes.content}>
      {product.item.orientation && (
        <Card className={classes.orientation} variant="black">
          <Icon.Bell className={classes.icon} size="2x" />
          <h3 className={classes.title}>Instruções da empresa</h3>
          <p className={classes.text}>{product.item.orientation}</p>
        </Card>
      )}
      <div className={classes.deliverables}>
        <form onSubmit={handleSubmit}>
          <FormGroup label="Link da entrega">
            <FormInputGroup>
              <FormInputGroup.Prepend>
                <Icon.Link />
              </FormInputGroup.Prepend>
              <FormText type="url" onChange={setCurrent} value={current} required />
              <FormInputGroupAppend>
                <button>SALVAR</button>
              </FormInputGroupAppend>
            </FormInputGroup>
          </FormGroup>
        </form>
      </div>
    </div>
  )
}
