import React from 'react'

import { useFilterContext } from '../../hooks/filters'
import { useMetaContext } from '../../hooks/meta'
import { Label } from 'components/Label'
import { FormText } from 'shared/form'

export default () => {
  const [meta] = useMetaContext()
  const [, filter] = useFilterContext()

  const onChange = (value: string) => filter('client_code', value)
  const onKeyPress = (e: any) => (e.key === 'Enter' ? meta('update') : null)

  return (
    <Label className="long">
      <p>Ref. do Cliente</p>
      <FormText
        style={{ flex: '0', minHeight: 38, marginTop: 0 }}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder="A referência preenchida ao criar a sessão"
      />
    </Label>
  )
}
