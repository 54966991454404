import React from 'react'

import { Card } from 'shared/ui/card/Card'
import classes from './Recover.module.scss'

export default () => {
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.content}>
          <span>Obrigado!</span>
          <span>Você pode olhar seu endereço de e-mail que </span>
        </div>
      </Card>
    </div>
  )
}
