import React, { useEffect, useState, useContext } from 'react'

const useApp = (): State<App> => {
  const [app, setApp] = useState<App>({})

  useEffect(() => {
    localStorage.setItem('app', JSON.stringify(app))
  }, [app])

  return [app!, setApp]
}

const AppContext = React.createContext<State<App> | undefined>(undefined)

export const useAppContext = (): State<App> => useContext(AppContext)!

export const AppContextProvider: React.FC = props => {
  const app = useApp()
  return <AppContext.Provider value={app} {...props} />
}
