import React from 'react'

import Header from '../../components/Index/Header'
import HowItWorks from '../../components/Index/HowItWorks'
import Gallery from '../../components/Index/Gallery'

const Home = () => {
  return (
    <>
      <Header />
      <HowItWorks />
      <Gallery />
    </>
  )
}

export default Home
