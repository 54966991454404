import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Steps from '../../../components/Steps'
import Btn from '../../../components/Button'

import CallProfile from '../../../components/Profile/CallProfile'

import Img from '../../../assets/images/side-image.png'
import { changePassword } from '../../../services/password'

import { Wrapper } from '../../../components/Wrapper'
import { Main } from '../../../components/Main'
import { Before } from '../../../components/Before'
import { Content } from '../../../components/Content'
import { Call } from '../../../components/Call'
import { Text } from '../../../components/Text'
import { Form } from '../../../components/Form'
import { Label } from '../../../components/Label'
import { Input } from '../../../components/Input'

const Checkbox = styled.label`
  font-size: 16px;
  line-height: 19px;

  a {
    text-decoration: underline;
    color: #0aa0d2;
  }
`

const HelpMessage = styled.h5`
  margin: 0 0 10px 0;
  font-weight: lighter;
  color: red;
`

const Terms = styled.a``

const Login = () => {
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const [showAgreeMessage, setShowAgreeMessage] = useState(false)

  const passwordInput = useRef<any>()

  const isValid = password.length >= 6 && password === repeatPassword && acceptedTerms

  const changeInputPassword = (e: any) => {
    setShowAgreeMessage(false)
    setPassword(e.target.value)
  }
  const changeRepeatPassword = (e: any) => {
    setShowAgreeMessage(false)
    setRepeatPassword(e.target.value)
  }
  const changeAcceptedTerms = () => {
    setShowAgreeMessage(false)
    setAcceptedTerms(!acceptedTerms)
  }

  const getHelpMessage = () => {
    if (password.length < 6) {
      return 'A senha precisa ter pelo menos seis caracteres.'
    }

    if (password !== repeatPassword) {
      return 'As senhas são diferentes.'
    }
    return 'É necessário concordar com os termos antes de continuar.'
  }

  const saveForm: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void = e => {
    if (isValid) {
      return changePassword(password)
    }

    e.preventDefault()
    setShowAgreeMessage(true)

    passwordInput.current.focus()
  }

  return (
    <Wrapper>
      <Main>
        <Before>
          <img src={Img} alt="sofa" />
        </Before>
        <Content>
          <Steps className={'password'} />

          <Call>Criar senha</Call>
          <Text>Precisamos criar uma senha para o seu acesso à plataforma.</Text>

          <CallProfile />

          <Form className="password">
            <Label className="password">
              Crie sua senha
              <Input
                autoFocus
                ref={passwordInput}
                className={password === repeatPassword && password.length > 0 ? 'valid' : 'error'}
                type="password"
                name="password"
                onChange={changeInputPassword}
                placeholder="Mínimo seis caracteres"
              />
            </Label>
            <Label className="password">
              Por favor, repita a senha
              <Input
                className={password === repeatPassword && password.length > 0 ? 'valid' : 'error'}
                type="password"
                name="repeat-password"
                onChange={changeRepeatPassword}
                placeholder="Por favor, repita a senha"
              />
            </Label>
            {showAgreeMessage === true ? <HelpMessage>{getHelpMessage()}</HelpMessage> : ''}
            <Checkbox>
              <Input name="isGoing" onChange={changeAcceptedTerms} checked={acceptedTerms} type="checkbox" /> Li e
              concordo com os{' '}
              <Terms target="_blank" href="https://www.birdie.com.br/3D/termos-de-uso/">
                Termos de Uso e a Política de Privacidade
              </Terms>{' '}
              da plataforma.
            </Checkbox>
          </Form>
          <Link onClick={saveForm} to="/onboard/company">
            <Btn className={isValid ? '' : 'invalid'}>Avançar →</Btn>
          </Link>
        </Content>
      </Main>
    </Wrapper>
  )
}

export default Login
