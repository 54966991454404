import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, FormGroup, FormText } from 'shared/form'
import { Button } from 'shared/ui/button/Button'

import { Card } from 'shared/ui/card/Card'
import { toFormData } from 'shared/util/form-data'
import classes from './Recover.module.scss'

export default () => {
  const [email, setEmail] = useState<string>()
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const params: RequestInit = {
      method: 'POST',
      body: toFormData({ email })
    }

    fetch('/api/auth/recover', params).then(() => history.push('/login/recover/done'))
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.content}>
          <div className={classes.logo}>
            <img src="https://birdie.com.br/img/logo-birdie.svg" alt="computer icon" />
          </div>
          <h1>Recupere sua conta</h1>
          <span>
            Podemos ajudá-lo a recuperar a redefinir a sua senha e informações de segurança. Primeiro, insira seu e-mail
            cadastrado na Birdie e siga as instruções a seguir.
          </span>
          <Form onSubmit={onSubmit}>
            <FormText value={email} onChange={setEmail} type="email" placeholder="e-mail" required />
            <FormGroup className={classes.buttons}>
              <Button type="button" onClick={goBack} outline>
                Voltar
              </Button>
              <Button type="submit">Próximo</Button>
            </FormGroup>
          </Form>
        </div>
      </Card>
    </div>
  )
}
