import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './CardHeader.module.scss'

interface CardHeaderProps {
  className?: string
}

export const CardHeader: React.FC<CardHeaderProps> = ({ className, ...props }) => {
  const klass = classNames(classes.header, className)
  return <div className={klass} {...props} />
}
