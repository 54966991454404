import React, { Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { ClientPaymentPlan } from 'shared/enum/client/ClientPaymentPlan'

import { Footer } from 'core/footer/Footer'
import { Navbar } from 'core/navbar/Navbar'

import Home from './pages/index/Home'
import { Login } from './pages/Login/Login'
import Onboard from './pages/Onboard'
import { Sessions } from './pages/sessions/Sessions'
import Recover from './pages/Login/Recover'
import Done from './pages/Login/Recover/Done'
import Profile from './pages/Profile'
import { Payment } from './pages/payment/Payment'
import { Register } from './pages/register/Register'
import { Scheduling } from './pages/scheduling/Scheduling'

import { useProfileContext } from 'hooks/ProfileContext'
import { Thanks } from 'pages/register/Thanks'
import { Error } from 'pages/register/Error'

export const Routes: React.FC = () => {
  const [profile] = useProfileContext()

  return profile ? (
    <Fragment>
      <Navbar />
      <Switch>
        <Route path="/calendar/:id" component={Scheduling} />
        {profile.client.payment_plan === ClientPaymentPlan.PREPAID && <Route path="/payment" component={Payment} />}
        <Route path="/sessions" component={Sessions} />
        <Route path="/profile" component={Profile} />
        {profile.first_login ? <Route path="/onboard" component={Onboard} /> : <Redirect to="/sessions" />}
      </Switch>
      <Footer />
    </Fragment>
  ) : (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/pre-cadastro" component={Register} />
      <Route exact path="/pre-cadastro/obrigado" component={Thanks} />
      <Route exact path="/pre-cadastro/erro" component={Error} />
      <Route exact path="/login/recover" component={Recover} />
      <Route exact path="/login/recover/done" component={Done} />
      <Route path="/login" component={Login} />
      <Route path="/calendar/:id" component={Scheduling} />
      <Redirect to="/login" />
    </Switch>
  )
}
