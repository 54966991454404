import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { SessionDeliverables } from 'shared/components/deliverables/Deliverables'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card } from 'shared/ui/card/Card'
import { classNames } from 'shared/util/class-names'
import { useSession } from 'shared/hooks/data/session'

import { SessionViewInfo } from './components/SessionViewInfo'

import { Ratings } from './components/Ratings'
import { Container } from 'shared/ui/container/Container'
import { Schedule } from './components/Schedule'
import { Address } from './components/Address'
import { ResponsibleInformation } from './components/ResponsibleInformation'
import { VerticalData } from './components/VerticalData'
import { Service } from './components/Service'
import { Provider } from './components/Provider'

import classes from './SessionView.module.scss'

export const SessionView: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [param, setParam] = useState(match.params)
  const session = useSession(param)

  const handleChange = () => setParam({ id: param.id })

  if (!session) {
    return null
  }

  return (
    <Container className={classes.container}>
      <Breadcrumb>
        <BreadcrumbItem>Ensaios</BreadcrumbItem>
        <BreadcrumbItem>Ensaio {session.id}</BreadcrumbItem>
      </Breadcrumb>
      {session && (
        <main className={classNames(classes.wrapper, classes.grid)}>
          <div className={classes.sideline}>
            <SessionViewInfo session={session} onChange={handleChange} />
            <Schedule session={session} />
            <Provider session={session} />
            <Address session={session} />
            <Ratings session={session} />
            <Service session={session} />
            <ResponsibleInformation session={session} />
            <VerticalData session={session} />
            <div className={classNames(classes.card)}>
              <p className={classes.title}>Informações extras</p>
              <p className={classes.info}>{session.vertical_data?.additional_info || '-'}</p>
            </div>
          </div>
          <div className={classes.sidecolumn}>
            {session.products?.length > 0 ? (
              <SessionDeliverables category="client" session={session} />
            ) : (
              <section className={classes.line}>
                <Card shadow variant="700">
                  <h2>Não há entregáveis no momento.</h2>
                  <h3>Volte novamente mais tarde.</h3>
                </Card>
              </section>
            )}
          </div>
        </main>
      )}
    </Container>
  )
}
