import styled from 'styled-components'

export const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  padding: 0;
  margin: 0 0 20px;

  &.users {
    font-weight: 700;
    text-align: left;
    margin: 10px 0;
    color: #000;
  }
`
