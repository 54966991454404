import { Footer } from 'core/footer/Footer'
import { Navbar } from 'pages/scheduling/components/Navbar'
import React from 'react'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'

import classes from './Register.module.scss'

export const Error: React.FC = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Card variant="light" shadow className={classes.card}>
          <h1>Houve um erro em seu cadastro!</h1>
          <span>
            Verifique com a Equipe Birdie para acessar a plataforma clicando{' '}
            <a
              target="blank"
              href="https://api.whatsapp.com/send?phone=5511937309960&text=Olá! Não consegui me cadastrar!">
              aqui!
            </a>
          </span>
        </Card>
      </Container>
      <Footer />
    </>
  )
}
