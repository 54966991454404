import React from 'react'

import { classNames } from '../util/class-names'

import classes from './FormTextArea.module.scss'

interface FormTextAreaProps {
  readOnly?: boolean
  onChange?: (_: string) => void
  rows?: number
  value?: string
}

export const FormTextArea: React.FC<FormTextAreaProps> = ({ onChange, value, ...props }) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event.target.value)
  }
  const textarea = {
    className: classNames(classes.textarea),
    onChange: handleChange,
    value
  }
  return <textarea {...textarea} {...props} />
}
