import React from 'react'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

const ListServices = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
  margin: 0 0 15px 0;

  @media only screen and (min-width: 540px) {
    grid-template-columns: 1fr 1fr;
    gap: 14px;
  }

  @media only screen and (min-width: 720px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }

  @media only screen and (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }

  @media only screen and (min-width: 1124px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 18px;
  }
`

const Service = styled.div`
  width: 100%;
  padding: 40px 30px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #b2b2b2;
  box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
  box-sizing: border-box;
`

const Img = styled(ReactSVG)`
  max-width: 50px;
  width: 100%;
  height: auto;

  color: #0aa0d2;

  svg {
    color: #0aa0d2;
  }
`

const Text = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  box-sizing: border-box;
`

const Services = (props: any) => {
  const { items } = props

  return (
    <ListServices>
      {items
        .filter((item: any) => item.item.item_id === null ?? false)
        .sort((item: any, item2: any) => (item.item.id > item2.item.id ? 1 : -1))
        .map((item: any, index: number) => {
          return (
            <Service key={index}>
              <Img src={item.item.icon} />
              <Text>{item.name}</Text>
            </Service>
          )
        })}
    </ListServices>
  )
}

export default Services
